import IvenoChecker from "./IvenoChecker";
import _ from "underscore";
import numeral from "numeral";
import dayjs from "dayjs";

const IvenoFormat = (function () {

	let defaultCurrencyCode = null;

	const _name = (lastName, firstName, middleName, defaultName) => {
		let names = [];
		if (!IvenoChecker.isEmpty(lastName)) {
			names.push(lastName)
		}
		if (!IvenoChecker.isEmpty(firstName)) {
			names.push(firstName)
		}
		if (!IvenoChecker.isEmpty(middleName)) {
			names.push(middleName)
		}
		if (_.isEmpty(names) && !IvenoChecker.isEmpty(defaultName)) {
			return defaultName;
		}
		return names.join(" ");
	}

	const _truncatedName = (lastName, firstName, middleName, defaultName) => {
		let names = [];
		if (!IvenoChecker.isEmpty(lastName)) {
			names.push(lastName + " ")
		}
		if (!IvenoChecker.isEmpty(firstName)) {
			names.push(firstName.substring(0, 1) + ".")
		}
		if (!IvenoChecker.isEmpty(middleName)) {
			names.push(middleName.substring(0, 1) + ".")
		}
		if (_.isEmpty(names) && !IvenoChecker.isEmpty(defaultName)) {
			return defaultName;
		}
		return names.join("");
	}

	const _number = (val) => {
		if (!_.isNumber(val)) {
			return val;
		}
		return numeral(val).format('0.,0');
	}

	const _money = (val, currencyCode) => {
		if (IvenoChecker.isEmpty(val)) {
			return [_number(0), currencyCode].join(" ");;
		}
		if (!IvenoChecker.isEmpty(currencyCode)) {
			return [_number(val), currencyCode].join(" ");
		}
		return _.compact([_number(val), defaultCurrencyCode]).join(" ");
	}

	const _strRepeat = (input, multiplier) => {
		return new Array(multiplier + 1).join(input);
	}

	const _float = (val, digits, defaultValue) => {
		if (val !== null) {
			if (_.isNumber(digits)) {
				return numeral(val).format('0,0.' + _strRepeat("0", digits));
			} else {
				let n = numeral(val).value();
				n = (n - Math.floor(n)).toString();
				if (n.length > 2) {
					return numeral(val).format('0,0.' + _strRepeat("0", n.length - 2));
				}
				return numeral(val).format('0,0');
			}
		}
		return defaultValue;
	}

	const _int = (val, zeroDefault) => {
		if (val === 0 && zeroDefault) {
			return zeroDefault;
		}
		return numeral(val).format('0');
	}

	const _date = (val, withTime) => {
		if (withTime === true) {
			return dayjs(val, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY HH:mm");
		}
		return dayjs(val, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY");
	}

	const _fullDate = (val, withTime) => {
		if (withTime === true) {
			return dayjs(val, "DD.MM.YYYY HH:mm").format("LLL HH:mm");
		}
		return dayjs(val, "DD.MM.YYYY HH:mm").format("LLL");
	}

	const _getNumberAsStringSuffix = (val, singular, plural, many) => {
		let _singular = singular;
		let _plural = plural;
		let _many = many;
		if (_.isArray(singular)) {
			_singular = singular[0];
			_plural = singular[1];
			_many = singular[2];
		}
		let n = val * 1;
		n = Math.abs(n) % 100;
		let n1 = n % 10;
		if (n > 10 && n < 20) return _many;
		if (n1 > 1 && n1 < 5) return _plural;
		if (n1 === 1) return _singular;
		return _many;
	}

	const _convertToTreeTable = (items, parentID, nameField) => {
		return _.map(_.where(items, {parentID: parentID}), i => {
			let newItem = JSON.parse(JSON.stringify(i));
			if (_.find(items, {parentID: i.id}) !== undefined) {
				newItem.children = _convertToTreeTable(items, i.id, nameField);
			}
			return newItem;
		});
	}

	const _convertToTree = (items, parentID, nameField) => {
		return _.map(_.where(items, {parentID: parentID}), i => {
			return {
				value: i.id,
				label: i[nameField],
				isLeaf: _.find(items, {parentID: i.id}) === undefined,
				children: _convertToTree(items, i.id, nameField)
			}
		});
	}

	const _convertToTreeKeyTitle = (items, parentID, nameField) => {
		return _.map(_.where(items, {parentID: parentID}), i => {
			return {
				key: i.id,
				title: i[nameField],
				isLeaf: _.find(items, {parentID: i.id}) === undefined,
				children: _convertToTreeKeyTitle(items, i.id, nameField)
			}
		});
	}

	const _convertToTreeValueTitle = (items, parentID, nameField) => {
		return _.map(_.where(items, {parentID: parentID}), i => {
			return {
				value: i.id,
				title: i[nameField],
				isLeaf: _.find(items, {parentID: i.id}) === undefined,
				children: _convertToTreeValueTitle(items, i.id, nameField)
			}
		});
	}

	return {
		setDefaultCurrencyCode: (currencyCode) => {
			defaultCurrencyCode = currencyCode;
		},
		name: _name,
		truncateName: _truncatedName,
		money: _money,
		number: _number,
		float: _float,
		int: _int,
		date: _date,
		fullDate: _fullDate,
		toNumberWithSuffix: (val, singular, plural, many, onlySuffix) => {
			if (onlySuffix) {
				return _getNumberAsStringSuffix(val, singular, plural, many)
			}
			return [val, _getNumberAsStringSuffix(val, singular, plural, many)].join(" ");
		},
		toTreeTable: _convertToTreeTable,
		toTree: _convertToTree,
		toTreeKeyTitle: _convertToTreeKeyTitle,
		toTreeValueTitle: _convertToTreeValueTitle
	}
})()

export default IvenoFormat;
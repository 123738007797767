import { useMemo } from 'react';
import { useUpdate } from 'ahooks';
import Controller from '../../../Libs/Controller';
import Rule from '../../../Libs/Rule';
import Config from '../../../Config/Config';
import { AuthButton, ActionButton, EmailInput, PasswordInput } from '../../../Components/Auth';
import { Box } from '@mui/material';

export const SignIn = function ({ setMode, setModeChange }) {
	const update = useUpdate();
	const controller = useMemo(() => {
		const c = new Controller(null, 'auth');
		c.setStructure({
			userLogin: '',
			userPassword: ''
		});
		c.clear();
		c.addObserver(update);
		c.addRule('userLogin', Rule.isEmail, 'Электронный адрес введен не корректно');
		c.addRule('userPassword', Rule.notEmpty, 'Пароль введен не корректно');
		return c;
	}, [update]);

	const login = () => {
		controller
			.savePromise()
			.then(() => {
				Config.setToken(controller.getData('token'));
			})
			.catch(() => {});
	};

	return (
		<Box
			component="form"
			sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.5rem' }}
		>
			<EmailInput action="userLogin" controller={controller} />
			<PasswordInput
				action="userPassword"
				controller={controller}
				rememberPass
				changePass={setModeChange}
			/>
			<AuthButton onClick={login} controller={controller}>
				Войти
			</AuthButton>
			<ActionButton onClick={setMode}>Зарегистрироваться</ActionButton>
		</Box>
	);
};

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import SendIcon from '@mui/icons-material/Send';
import { actionsData } from './actionsData';

export const useButtonMode = (controller, pathname) => {
	const isSocPublish = controller.getSocialData('status') === 'publish';
	const isSocDelay = controller.getSocialData('status') === 'delay';
	const webCreateAction = actionsData.socialActionsSend.filter(
		(act) => act.action === 'WEB_CREATE'
	);
	const params = {
		icon: (
			<EditOutlinedIcon
				sx={{
					fontSize: '30px'
				}}
			/>
		),
		color: 'red',
		actions: actionsData.defaultActions,
		mode: 'MAIN'
	};

	if (pathname === '/social-settings') {
		return {
			...params,
			icon: <ControlPointDuplicateIcon />,
			color: 'blue',
			actions: actionsData.socialActions
		};
	}

	if (!isSocPublish && (pathname === '/social' || pathname.split('-')[0] === '/act')) {
		return {
			...params,
			icon: <SendIcon />,
			color: 'blue',
			mode: 'SOCIAL',
			actions: isSocDelay ? webCreateAction : actionsData.socialActionsSend
		};
	}
	if (isSocPublish || (controller.readonly() && controller.getIsEdit())) {
		return {
			...params,
			color: 'gray',
			icon: <SendIcon />
		};
	}
	if (controller.getIsEdit()) {
		return { ...params, icon: <SendIcon /> };
	}

	return params;
};

import styled from 'styled-components';
import { ReactComponent as TimerIcon } from '../../../Assets/timer.svg';
import dayjs from 'dayjs';

const NewsCellPublicationDateStyle = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	gap: 8px;
	font-size: 12px;

	&.future {
		color: #dd2c34 !important;
	}

	svg {
		width: 1em;
		height: 1em;
	}

	&.dark {
		svg {
			path {
				stroke: var(--color-gray);
			}
		}
	}
`;

export const NewsCellPublicationDate = function ({ pubDate, style, dark }) {
	const getValue = () => {
		let pb = dayjs(pubDate);
		let now = dayjs();
		if (pb.format('DD.MM.YYYY') === now.format('DD.MM.YYYY')) {
			return pb.format('HH:mm');
		}
		if (pb.format('DD.MM.YYYY') === now.subtract(1, 'day').format('DD.MM.YYYY')) {
			return 'Вчера в ' + pb.format('HH:mm');
		}
		return pb.format('LLL');
	};

	let classes = [];
	if (dark) {
		classes.push('dark');
	}
	// if (dayjs(pubDate).isAfter(dayjs())) {
	//     classes.push("future")
	// }

	return (
		<NewsCellPublicationDateStyle style={style} className={classes.join(' ')}>
			<TimerIcon />
			{getValue()}
		</NewsCellPublicationDateStyle>
	);
};

import { Box, Typography } from '@mui/material';
import { ReactComponent as LogoAuth } from '../../Assets/LogoAuth.svg';

export const AuthLogo = ({ theme }) => (
	<Box
		sx={{
			marginBottom: '2rem',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			gap: '.5rem'
		}}
	>
		<LogoAuth />
		<Typography color={theme?.palette.divider.main} variant="h4">
			Пиар
		</Typography>
	</Box>
);

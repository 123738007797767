import React from 'react';

import { BrowserRouter, Route } from 'react-router-dom';
import { Navigate, Routes } from 'react-router';
import Page404 from '../Pages/404/Page404';
import { MainLayout } from '../Pages/Layout/MainLayout';
import AuthPage from '../Pages/AuthPage/AuthPage';
import Config from './Config';

const SettingsPage = React.lazy(() => import('../Pages/SettingsPage/SettingsPage'));
const ProfileSettings = React.lazy(() => import('../Pages/ProfileSettings/ProfileSettings'));
const SimpleArticleEdit = React.lazy(() => import('../Pages/SimpleArticleEdit/SimpleArticleEdit'));
const ArticlesPage = React.lazy(() => import('../Pages/ArticlesPage/ArticlesPage'));
const SocialPage = React.lazy(() => import('../Pages/SocialPage/SocialPage'));
const TelegramEditor = React.lazy(() => import('../Pages/TelegramEditor/TelegramEditor'));
const CalendarPage = React.lazy(() => import('../Pages/CalendarPage/CalendarPage'));

export const AppRoutes = () => {
	const isAuth = Config.isAuth();

	return (
		<BrowserRouter future={{ v7_startTransition: true }}>
			{isAuth ? (
				<Routes>
					<Route path="/embed/:id/:token" element={<TelegramEditor />} />
					<Route element={<MainLayout />} errorElement={<Page404 />}>
						<Route path={'/drafts'} element={<ArticlesPage />} />
						<Route path={'/moderation'} element={<ArticlesPage />} />
						{/* <Route path={'/social'} element={<ArticlesPage />} /> */}
						<Route path={'/social-settings'} element={<SocialPage />} />
						<Route path={'/calendar'} element={<CalendarPage />} />
						<Route path={'/mailing'} element={<ArticlesPage />} />
						<Route path={'/site'} element={<ArticlesPage />} />
						<Route path={'/settings'} element={<SettingsPage />} />
						<Route path={'/profile'} element={<ProfileSettings />} />
						<Route path={'/'} element={<ArticlesPage category={null} />} />
						<Route path={'/reg'} element={<Navigate to="/" />} />

						<Route path={'*'} element={<SimpleArticleEdit />} />
					</Route>
				</Routes>
			) : (
				<Routes>
					<Route path={'/'} element={<AuthPage />} />
					<Route path={'/reg'} element={<AuthPage />} />
					<Route path="/embed/:id/:token" element={<TelegramEditor />} />
					<Route path="/change/:token" element={<AuthPage isChangePass />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			)}
		</BrowserRouter>
	);
};

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Box, Button } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';

export const DeleteDialog = ({ open, setOpen, remove }) => (
	<Dialog onClose={() => setOpen(false)} open={open}>
		<DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
			<NewReleasesOutlinedIcon /> Подтвердите удаление канала
		</DialogTitle>
		<Box sx={{ display: 'flex', justifyContent: 'center', padding: '1rem', gap: '1rem' }}>
			<Button startIcon={<DeleteOutlineOutlinedIcon />} variant="outlined" onClick={remove}>
				Удалить
			</Button>
			<Button
				startIcon={<CancelOutlinedIcon />}
				variant="outlined"
				onClick={() => setOpen(false)}
			>
				Отмена
			</Button>
		</Box>
	</Dialog>
);

import { Alert } from '@mui/material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { forwardRef } from 'react';

const CustomSuccessAlert = forwardRef(({ message }, ref) => {
	const children = message.props.children;
	return (
		<Alert
			ref={ref}
			sx={{ width: '100%', border: '1px solid var(--color-system-green)' }}
			severity="success"
		>
			{children}
		</Alert>
	);
});

const CustomErrorAlert = forwardRef(({ message }, ref) => {
	const children = message.props.children;

	return (
		<Alert ref={ref} sx={{ border: '1px solid var(--color-system-red)' }} severity="error">
			{children}
		</Alert>
	);
});

export const useNotification = () => {
	const success = (text) => enqueueSnackbar(<>{text.toString()}</>, { variant: 'success' });
	const error = (text) => enqueueSnackbar(<>{text.toString()}</>, { variant: 'error' });

	const SnackbarProviderComponent = ({ children }) => (
		<SnackbarProvider
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			Components={{
				success: CustomSuccessAlert,
				error: CustomErrorAlert
			}}
			maxSnack={3}
			autoHideDuration={2000}
		>
			{children}
		</SnackbarProvider>
	);

	return { success, error, SnackbarProviderComponent };
};

import axios from 'axios';

export async function downloadImageAsBase64(imageUrl) {
	try {
		const response = await axios.get(imageUrl, { responseType: 'blob' });
		const blob = response.data;

		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(blob);
		});
	} catch (error) {
		throw new Error(`Ошибка скачивания изображения: ${error.message}`);
	}
}

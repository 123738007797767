import { Box, Button, Typography } from '@mui/material';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';

export const Step1 = ({ setStep }) => (
	<Box className="text-block">
		<Typography
			variant="h5"
			fontWeight={700}
			sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
		>
			<LibraryAddOutlinedIcon /> Добавление Telegram
		</Typography>
		<Typography variant="body1" textAlign="center" fontWeight={700}>
			1 шаг
			<br />
			Добавьте специального бота в администраторы канала
		</Typography>
		<Typography variant="body2">
			<ol>
				<li>Зайдите в ваш канал, выберите в меню пункт “Управление каналом”</li>
				<li>Нажмите на вкладку “Администраторы” - ”Добавить администратора”</li>
				<li>В строке поиска найдите нашего специального бота “Репостер-РР Пиар” @rr_pr_bot</li>
				<li>Сохраните настройки</li>
			</ol>
		</Typography>
		<Button onClick={() => setStep(2)} variant="contained">
			Далее
		</Button>
	</Box>
);

import { Box, styled, useMediaQuery } from '@mui/material';
import { MainSideBar } from './MainSideBar';
import { ChatGPTDrawer } from '../SimpleArticleEdit/ChatGptDrawer/ChatGPTDrawer';
import { CreationButton } from '../../Components/CreationButton/CreationButton';
import { useEffect, useState } from 'react';
import IvenoBus from '../../Libs/Iveno/IvenoBus';
import { useLocation } from 'react-router';
import { offStateEmitID } from './MainHeader';

const BoxWrapStyle = styled(Box)`
	display: flex;
	height: 100%;
	position: relative;
`;

const BoxContentStyle = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	display: 'flex',
	position: 'relative',
	padding: '10px 0 20px 0',

	backgroundColor: theme.palette.background.main
}));

const BoxStyle = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isOpen'
})(({ isOpen }) => ({
	position: 'absolute',
	transform: isOpen && 'translate(-50%, 0%)',
	top: '0',
	left: '50%',
	transition: 'width  300ms',
	width: '90%'
}));

const PseudoBlock = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'getWith'
})(({ getWith }) => ({
	width: getWith(),
	transition: 'width 300ms'
}));

export const MainContent = ({ children }) => {
	const [isOpen, setIsOpen] = useState({ disable: false, active: true, activeMobile: false });
	const isLargeScreen = useMediaQuery('(min-width:1070px)');

	const { pathname } = useLocation();

	useEffect(() => {
		const id = IvenoBus.on('updateState', (newState) => setIsOpen(newState), null, isOpen);

		return () => {
			IvenoBus.off(id);
		};
	}, [isOpen]);

	useEffect(() => {
		IvenoBus.emit(offStateEmitID);
	}, [pathname]);

	const getWith = () => {
		if (isOpen.disable) {
			return isOpen.activeMobile && '0';
		}
		return isOpen.active ? '20%' : 'calc(40% - 70px)';
	};

	useEffect(() => {
		if (isLargeScreen) {
			setIsOpen((prev) => ({ ...prev, disable: false, active: true }));
		} else {
			setIsOpen((prev) => ({ ...prev, disable: true, active: false }));
		}
	}, [isLargeScreen]);

	return (
		<BoxWrapStyle>
			<MainSideBar isOpen={isOpen} setIsOpen={setIsOpen} />
			<BoxContentStyle>
				<BoxStyle isOpen={isOpen}>{children}</BoxStyle>
			</BoxContentStyle>
			<PseudoBlock getWith={getWith} />
			<ChatGPTDrawer />
			<CreationButton />
		</BoxWrapStyle>
	);
};

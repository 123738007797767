import { PlusOutlined } from '@ant-design/icons';
import { styled } from '@mui/material';
import { Upload } from 'antd';
import { useEffect, useState } from 'react';
import { useNotification } from '../../../hooks/useNotification';
import Iveno from '../../../Libs/Iveno/Iveno';

const UploadStyle = styled(Upload)`
	border-top: 1px solid #eee;
	padding-top: 1rem;
	.ant-upload-list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	.ant-upload {
		button {
			cursor: pointer;
		}
	}
`;

export const uploadImageEventID = 'upload-image-event-id';

export const UploadImage = ({ controller, quantity }) => {
	const [fileList, setFileList] = useState([]);

	const { error } = useNotification();

	const isSocPublish = controller.getSocialData('status') === 'publish';

	useEffect(() => {
		const id = Iveno.Bus.on(uploadImageEventID, (file) => {
			setFileList((prev) => {
				const updatedFileList = [...prev, file];
				handleChange({ fileList: updatedFileList });
				return updatedFileList;
			});
		});
		return () => Iveno.Bus.off(id);
	}, []);

	const handleChange = ({ fileList }) => {
		let fileClass;

		const filteredFiles = fileList.filter((file) => {
			const fileExtensions = [['photo_format', '.jpg', '.jpeg', '.png', '.gif']];

			const getFileType = (file) => {
				return `.${file.originFileObj.name.split('.').pop()}`;
			};

			fileClass = fileExtensions.find((el) => el.includes(getFileType(file)))?.[0];

			if (!fileClass) {
				error('Ассистент пока не умеет читать такие файлы');
				return false;
			}

			file['fileClass'] = fileClass;

			return true;
		});
		setFileList(filteredFiles);
		controller.setSocialData(
			'photoPost',
			fileList.map((file) => file)
		);
	};

	const uploadButton = (
		<button
			style={{
				border: 0,
				background: 'none'
			}}
			type="button"
		>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8
				}}
			>
				Загрузить
			</div>
		</button>
	);

	return (
		<UploadStyle
			disabled={isSocPublish}
			listType="picture-card"
			beforeUpload={() => false}
			fileList={fileList}
			onChange={handleChange}
			showUploadList={{ showPreviewIcon: false }}
			accept="*"
		>
			{fileList.length >= quantity ? null : uploadButton}
		</UploadStyle>
	);
};

import { InputAdornment, TextField } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useValidate } from '../../hooks/useValidate';
import { useEffect } from 'react';

export const EmailInput = ({ controller, action = 'email', defaultValue }) => {
	const { isValue, setValue, isError, isHelperText } = useValidate(controller, action);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	return (
		<TextField
			type="email"
			fullWidth
			id="standard-basic"
			label="Электронная почта"
			variant="standard"
			value={isValue}
			onChange={(e) => setValue(e.target.value)}
			disabled={controller.loading}
			error={isError}
			helperText={isHelperText}
			autoFocus
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<EmailOutlinedIcon />
					</InputAdornment>
				)
			}}
		/>
	);
};

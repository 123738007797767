import { useNavigate } from 'react-router';
import { useNotification } from './useNotification';
import { webhook } from '../Pages/SocialPage/webhook';

export const usePublish = (controller, open, setOpen, setOpenModal, mode) => {
	const navigate = useNavigate();

	const { error } = useNotification();

	if (mode === 'SOCIAL') {
		return async (e, action) => {
			if (!controller.getSocialData('textPost'))
				return error('Вы забыли добавить текст для поста');
			if (controller.socialValidate?.error) {
				return error(controller.socialValidate.error);
			}
			setOpen(true);

			if (action === 'WEB_CREATE') {
				setOpenModal(true);
				try {
					if (controller.getSocialData('id')) {
						await webhook.edit('ssEditPost', controller.getFullSocialData());
					} else {
						await webhook.create('ssAddPost', controller.getFullSocialData());
					}

					controller.clearSocialData();
					setOpen(false);
					setOpenModal(false);
					navigate('/drafts');
				} catch (err) {
					setOpenModal(false);
					error(err ? err : 'Ошибка сохранения поста');
				}
			}

			if (action === 'WEB_PUBLISH') {
				setOpenModal(true);

				let endpoint = controller.getSocialData('pubDate') ? 'sspubpostDel' : 'sspubpost';

				try {
					if (controller.getSocialData('id')) {
						// await webhook.edit('ssEditPost', controller.getFullSocialData());
						await webhook.publish(endpoint, controller.getFullSocialData());
					} else {
						const res = await webhook.create('ssAddPost', controller.getFullSocialData());
						await webhook.publish(endpoint, {
							id: res.id,
							...controller.getFullSocialData()
						});
					}
					controller.clearSocialData();
					setOpen(false);
					setOpenModal(false);
					navigate('/');
				} catch (err) {
					setOpenModal(false);
					error(err ? err : 'Ошибка публикации в социальные сети');
				}
			}
		};
	}

	return async () => {
		if (controller.readonly() && controller.getIsEdit()) return;

		controller.deleteRule('image');
		if (controller.getIsEdit()) {
			controller.checkData();
			if (controller.hasErrors()) {
				error('Не все обязательные поля заполнены');
				return;
			}
			setOpenModal(true);

			try {
				await controller.savePromise();
				await controller.publish();

				if (controller.getFullSocialData().telegram_id_chen) {
					const data = controller.getFullSocialData();

					function checkKey(data, textPost, textPostPhoto) {
						return data[textPost] || data[textPostPhoto] || null;
					}

					if (!checkKey(data, 'textPost', 'textPostPhoto')) {
						return error('Вы забыли добавить текст для поста');
					}

					const res = await webhook.create('ssAddPost', data);
					await webhook.publish('sspubpost', {
						id: res.id,
						...data
					});
				}
				setOpen(false);
				setOpenModal(false);
				navigate('/');
			} catch (err) {
				error(err);
				setOpenModal(false);
			}
		} else {
			setOpen(!open);
		}
	};
};

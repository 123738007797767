import { InputAdornment, TextField } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Utils from '../../../../Libs/Utils';
import { useEffect, useState } from 'react';

export const EmailInputAI = ({ email, setEmail }) => {
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!email) return setError(true);
		setError(Utils.isEmail(email));
	}, [email]);

	return (
		<TextField
			type="email"
			// fullWidth
			sx={{ width: '300px' }}
			id="standard-basic"
			// label="Электронная почта"
			placeholder="Электронная почта"
			error={!error}
			// helperText={!error && 'Некорректный email'}
			variant="outlined"
			value={email}
			onChange={(e) => setEmail(e.target.value)}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<EmailOutlinedIcon />
					</InputAdornment>
				)
			}}
		/>
	);
};

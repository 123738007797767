import { Box, IconButton, Paper, styled, Typography, useTheme } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import React, { useEffect, useRef, useState } from 'react';
import Iveno from '../../Libs/Iveno/Iveno';
import { SocialModal } from './SocialModal/SocialModal';
import { webhook } from './webhook';
import { Telegram } from '@mui/icons-material';
import { DeleteDialog } from './components/DeleteDialog';
import { ReactComponent as VK } from '../../Assets/Logo/vk_logo.svg';
import { ReactComponent as OK } from '../../Assets/Logo/ok_logo.svg';

const SocialPageStyle = styled(Box)`
	width: 100%;

	.articlesHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.articlesWrapper {
		display: flex;

		width: 100%;
		gap: 20px;
		align-items: flex-start;
		padding-bottom: 1rem;

		.break {
			display: block;
			height: 0.5rem;
		}
	}
`;

export const eventsID = {
	tg: 'open-social-modal-tg',
	vk: 'open-social-modal-vk',
	ok: 'open-social-modal-ok'
};

const SocialPage = () => {
	const [open, setOpen] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [channels, setChannels] = useState([]);
	const theme = useTheme();
	const [update, setUpdate] = useState(false);
	const idChenRef = useRef();
	const [newStep, setNewStep] = useState(null);

	useEffect(() => {
		webhook
			.get('ss')
			.then((res) => {
				setChannels(res);
			})
			.catch((err) => {});
		const idTg = Iveno.Bus.on(eventsID.tg, () => {
			setOpen(true);
			setNewStep(null);
		});
		const idVk = Iveno.Bus.on(eventsID.vk, () => {
			setOpen(true);
			setNewStep(4);
		});

		const idOk = Iveno.Bus.on(eventsID.ok, () => {
			setOpen(true);
			setNewStep(5);
		});
		return () => {
			Iveno.Bus.off(idTg);
			Iveno.Bus.off(idVk);
			Iveno.Bus.off(idOk);
		};
	}, [update]);

	const formattedText = (text) =>
		text?.split('\\n').map((item, index) => (
			<React.Fragment key={index}>
				{item}

				<span className="break" />
			</React.Fragment>
		));

	const deleteChannel = (id) => {
		idChenRef.current = id;
		setOpenDialog(true);
	};
	const handleRemove = () => {
		webhook.remove('ssDelete', idChenRef.current);
		setOpenDialog(false);
		setChannels((prev) => prev.filter((chanel) => chanel.telegram_id_chen !== idChenRef.current));
	};

	const icons = {
		style: {
			position: 'absolute',
			width: '50px',
			height: '50px',
			color: 'white',
			bottom: '0',
			right: '0',
			background: theme.palette.systemBlue.main,
			borderRadius: '50%',
			padding: '8px'
		},
		tg() {
			return <Telegram style={this.style} />;
		},
		vk() {
			return <VK style={this.style} />;
		},
		ok() {
			return <OK style={{ ...this.style, background: 'white' }} />;
		}
	};

	return (
		<>
			<SocialModal
				open={open}
				setOpen={setOpen}
				update={() => setUpdate(!update)}
				newStep={newStep}
			/>
			<DeleteDialog open={openDialog} setOpen={setOpenDialog} remove={handleRemove} />

			{channels?.status === null ? (
				<Box textAlign="center">У вас еще нет добавленных каналов</Box>
			) : (
				<>
					{channels?.map((channel) => (
						<SocialPageStyle key={channel.telegram_id_chen}>
							<div className="articlesWrapper">
								<Paper
									sx={{
										width: '100%',
										display: 'flex',
										padding: '38px',
										borderRadius: '25px',
										gap: '1rem',
										alignItems: 'center',
										'@media(max-width: 500px)': {
											flexDirection: 'column'
										}
									}}
									elevation={2}
								>
									<Box position="relative">
										<Box
											component={'img'}
											sx={{
												width: '140px',
												borderRadius: '50%'
											}}
											src={channel.telegram_img_chen}
										/>
										{icons[channel?.telegram_id_chen.split('-')[0]]()}
									</Box>

									<Box>
										<Typography variant="h5" fontWeight={700}>
											{channel.telegram_title_chen}
										</Typography>
										<span className="break" />
										<Typography
											sx={{
												display: '-webkit-box',
												WebkitBoxOrient: 'vertical',
												WebkitLineClamp: 5,
												overflow: 'hidden'
											}}
											variant="body1"
										>
											{formattedText(channel.telegram_description_chen)}
										</Typography>
									</Box>
									<IconButton
										sx={{
											marginLeft: 'auto',
											'@media(max-width: 500px)': {
												margin: '0 auto'
											}
										}}
										onClick={() => deleteChannel(channel.telegram_id_chen)}
									>
										<DeleteOutlineOutlinedIcon />
									</IconButton>
								</Paper>
							</div>
						</SocialPageStyle>
					))}
				</>
			)}
		</>
	);
};

export default SocialPage;

import { ArticleController } from '../Pages/SimpleArticleEdit/ArticleController';

export const articlesRegController = (() => {
	const controller = new ArticleController();

	const register = (update) => {
		if (update) {
			controller.addObserver(update);
		}
		return controller;
	};

	return register;
})();

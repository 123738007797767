import { useMediaQuery, useTheme } from '@mui/material';

export const useStylesButton = (open) => {
	const theme = useTheme();
	const isLargeScreen = useMediaQuery('(min-width:1070px)');
	const isMiddleScreen = useMediaQuery('(min-width:600px)');
	const widthSize = isLargeScreen ? 80 : isMiddleScreen ? 75 : 60;

	const colors = {
		red: theme.palette.systemRed.main,
		blue: theme.palette.systemBlue.main,
		gray: theme.palette.systemGray.main
	};

	const getSpeedDialStyle = (color) => ({
		borderRadius: '1rem',
		position: 'absolute',
		bottom: isLargeScreen ? 70 : !isMiddleScreen ? 40 : 50,
		right: isLargeScreen ? 110 : !isMiddleScreen ? 40 : 50,
		'.MuiButtonBase-root': {
			borderRadius: '28px',
			width: widthSize + 'px',
			height: widthSize + 'px',
			color: open ? colors[color] : theme.palette.common.white,
			background: !open ? colors[color] : theme.palette.common.white,
			'&:hover': {
				background: !open ? colors[color] : theme.palette.common.white
			}
		},

		// {
		// '&::after': {
		// 	content: '""'
		// position: 'absolute',
		// border: '4px solid #000'
		// width: '100%',
		// height: '100%',
		// borderRadius: '1.5rem'
		// }
		// '&::before': {
		// 	content: '""',
		// 	position: 'absolute',
		// 	border: '4px solid #000',
		// 	width: '100%',
		// 	height: '100%',
		// 	borderRadius: '1.5rem'
		// }
		// },

		'.MuiSpeedDialAction-fab': {
			backgroundColor: theme.palette.common.white,
			width: widthSize + 'px',
			height: widthSize / 2 + 'px',
			'&:hover': {
				background: theme.palette.common.white
			}
		}
	});

	const getSpeedDialActionStyle = (action) => ({
		display: 'flex',
		justifyContent: 'left',
		gap: '10px',
		padding: '5px 15px',
		width: 'auto !important',
		'&:after': {
			content: `"${action.name}"`,
			textTransform: 'lowercase',
			color: 'foreground.main',
			fontWeight: '700'
		}
	});

	return { getSpeedDialStyle, getSpeedDialActionStyle };
};

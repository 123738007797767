import React, { useEffect, useState } from 'react';
import {
	Drawer,
	List,
	ListItemText,
	ListItemButton,
	useTheme,
	Box,
	useMediaQuery,
	Collapse
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WebStoriesOutlinedIcon from '@mui/icons-material/WebStoriesOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import IvenoBus from '../../Libs/Iveno/IvenoBus';
import { NavLink } from 'react-router-dom';
import { handleLeftDrawerToggle, offStateEmitID } from './MainHeader';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import packageJson from '../../../package.json';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

const menuItems = [
	{
		text: 'Мои публикации',
		icon: <NewspaperIcon color="systemRed" />,
		label: '/',
		sublist: [
			{ text: 'Черновики', icon: <SaveIcon color="systemRed" />, label: '/drafts' },
			{
				text: 'Календарь',
				icon: <CalendarMonthOutlinedIcon color="systemRed" />,
				label: '/calendar'
			},
			{
				text: 'Модерация',
				icon: <ErrorOutlineIcon color="systemRed" />,
				label: '/moderation'
			}
		]
	},

	{
		text: 'Социальные сети',
		icon: <WebStoriesOutlinedIcon color="systemRed" />,
		label: '/social-settings'
	},
	{
		text: 'Рассылки',
		icon: <EmailOutlinedIcon color="systemRed" />,
		label: '/mailing'
	},
	{
		text: 'Мой сайт',
		icon: <PublicOutlinedIcon color="systemRed" />,
		label: '/site'
	}
];

export const MainSideBar = ({ isOpen, setIsOpen }) => {
	const theme = useTheme();

	const isLargeScreen = useMediaQuery('(min-width:1070px)');
	const isMiddleScreen = useMediaQuery('(min-width:600px)');
	const [open, setOpen] = useState(false);
	useEffect(() => {
		if (isLargeScreen) {
			handleLeftDrawerToggle(isLargeScreen);
		}
	}, [isLargeScreen]);

	useEffect(() => {
		const id = IvenoBus.on(offStateEmitID, () =>
			setIsOpen((prev) => ({ ...prev, activeMobile: false }))
		);

		return () => IvenoBus.off(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const drawerStyle = {
		style: {
			position: 'relative',
			backgroundColor: theme.palette.background.main,
			border: 'none',
			padding: '0 .5rem'
		}
	};

	const isWidthDrawer = () => {
		if (isOpen.disable) {
			return isOpen.activeMobile ? '250px' : '70px';
		} else {
			return isOpen.active ? '20%' : '70px';
		}
	};

	const isWidthList = () => {
		if (isOpen.disable) {
			return isOpen.activeMobile ? '100%' : '0';
		} else {
			return isOpen.active ? '100%' : '0';
		}
	};

	const isStatusMenu = () => {
		if (isOpen.disable) {
			return !!isOpen.activeMobile;
		} else {
			return !!isOpen.active;
		}
	};

	const variantDrawer = () => {
		if (!isMiddleScreen) return 'temporary';
		return isOpen.activeMobile ? 'temporary' : 'permanent';
	};

	const openSubList = (e, flag) => {
		if (flag) {
			e.preventDefault();
			setOpen(!open);
		} else {
			setOpen(!open);
		}
	};

	return (
		<>
			{isMiddleScreen && <Box style={{ width: `${isOpen.activeMobile ? '70px' : '0'}` }}></Box>}
			<Drawer
				sx={{
					zIndex: `${isOpen.activeMobile ? '18' : '17'}`,
					width: isWidthDrawer(),
					transition: '300ms',
					overflow: 'hidden'
				}}
				open={isOpen.activeMobile}
				variant={variantDrawer()}
				PaperProps={drawerStyle}
				onClose={handleLeftDrawerToggle}
			>
				<List
					style={{
						marginRight: isOpen.active && '2rem',
						display: 'flex',
						flexDirection: 'column',
						gap: '1px',
						position: 'fixed'
					}}
					sx={{
						'.MuiSvgIcon-root': {
							flex: isStatusMenu() ? '' : '1'
						}
					}}
				>
					{menuItems.map((item, index) => {
						if (item.sublist) {
							return (
								<React.Fragment key={item.label}>
									<NavLink to={item.label} style={{ textDecoration: 'none' }}>
										<ListItemButton
											onClick={isStatusMenu() ? () => {} : (e) => openSubList(e, false)}
											sx={{
												color: 'foreground.main',
												borderRadius: '100px',
												'&.MuiButtonBase-root': {
													aspectRatio: isStatusMenu() ? '' : '1/1',
													minHeight: '100%'
												},
												'&:hover': {
													background: '#fff'
												}
											}}
										>
											{item.icon}
											<Box
												style={{
													maxWidth: isWidthList(),
													overflow: 'hidden'
												}}
											>
												<ListItemText sx={{ marginLeft: '1rem' }} primary={item.text} />
											</Box>
											{isStatusMenu() && (
												<Box
													sx={{
														display: 'flex'
													}}
												>
													{open ? (
														<ExpandLess onClick={openSubList} />
													) : (
														<ExpandMore onClick={openSubList} />
													)}
												</Box>
											)}
										</ListItemButton>
									</NavLink>
									<Collapse in={open} timeout="auto" unmountOnExit>
										<List
											component="div"
											disablePadding
											sx={{ ml: isStatusMenu() ? '1rem' : 'none' }}
										>
											{item.sublist.map((item) => (
												<NavLink
													key={item.label}
													to={item.label}
													style={{ textDecoration: 'none' }}
												>
													<ListItemButton
														sx={{
															color: 'foreground.main',
															borderRadius: '100px',
															'&.MuiButtonBase-root': {
																aspectRatio: isStatusMenu() ? '' : '1/1'
															},
															'&:hover': {
																background: '#fff'
															}
														}}
													>
														{item.icon}

														<Box
															style={{
																maxWidth: isWidthList(),
																overflow: 'hidden'
															}}
														>
															<ListItemText
																sx={{ marginLeft: '1rem' }}
																primary={item.text}
															/>
														</Box>
													</ListItemButton>
												</NavLink>
											))}
										</List>
									</Collapse>
								</React.Fragment>
							);
						} else
							return (
								<NavLink
									key={item.label}
									to={item.label}
									style={{ textDecoration: 'none' }}
								>
									<ListItemButton
										sx={{
											color: 'foreground.main',
											borderRadius: '100px',
											'&.MuiButtonBase-root': {
												aspectRatio: isStatusMenu() ? '' : '1/1'
											},
											'&:hover': {
												background: '#fff'
											}
										}}
									>
										{item.icon}

										<Box
											style={{
												// maxWidth: isWidthList(),
												overflow: 'hidden'
												// aspectRatio: '1/1',
											}}
										>
											{isStatusMenu() && (
												<ListItemText sx={{ marginLeft: '1rem' }} primary={item.text} />
											)}
										</Box>
									</ListItemButton>
								</NavLink>
							);
					})}
				</List>
			</Drawer>
			<Box
				position="fixed"
				bottom=".8rem"
				left=".8rem"
				display={!isStatusMenu() && 'none'}
				color="systemGray.main"
				fontSize=".9rem"
				zIndex={20}
			>
				v.{packageJson?.version}
			</Box>
		</>
	);
};

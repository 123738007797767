import { useEffect, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import { ImageCropper } from '../../MainImageEditor/ImageCropper';
import Iveno from '../../../../Libs/Iveno/Iveno';
import { useLocation } from 'react-router';
import { uploadImageEventID } from '../../Components/UploadImage';
import DataUtils from '../../../../Libs/DataUtils';

export const AddToEditImgButton = ({ controller, result, setOpen }) => {
	const [isAdded, setIsAdded] = useState(false);
	const [file, setFile] = useState('');

	const imageRef = useRef(null);

	const { pathname } = useLocation();

	useEffect(() => {
		requestAnimationFrame(() => {
			imageRef.height = imageRef.current?.height;
		});
	}, [isAdded]);

	const addToEditor = () => {
		if (pathname === '/social') {
			DataUtils.fetchImageMetadataAndBase64(result)
				.then((file) => {
					Iveno.Bus.emit(uploadImageEventID, file);
					setOpen(false);
				})
				.catch(() => console.log('Не удалось скачать файл'));
			return;
		}

		setIsAdded(!isAdded);
		if (isAdded) {
			controller.setData('image', file);
			setOpen(false);
		}
	};

	return (
		<>
			<Box
				ref={imageRef}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					height: `${imageRef.current?.offsetWidth}px`,
					margin: '1rem 0'
				}}
			>
				{isAdded ? (
					<ImageCropper imageToCrop={result} setFile={setFile} controller={controller} />
				) : (
					<img style={{ width: '100%' }} src={result} alt="" />
				)}
			</Box>
			{controller.getIsEdit() && (
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					<Button variant="contained" onClick={addToEditor}>
						{isAdded || pathname === '/social' ? 'Вставить' : 'Главная'}
					</Button>
					{/* <Button
						variant="contained"
						onClick={() =>
							Iveno.Bus.emit('insertImage-event', { imageUrl: result, insertAtTop: true })
						}
					>
						Главная
					</Button> */}

					{pathname !== '/social' && (
						<Button
							variant="contained"
							onClick={() => Iveno.Bus.emit('insertImage-event', { imageUrl: result })}
						>
							В текст
						</Button>
					)}
				</Box>
			)}
		</>
	);
};

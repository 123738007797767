export const useValidate = (controller, action) => {
	if (!controller) return;

	const getData = () => {
		return controller.getData(action, '');
	};

	const setValue = (val) => {
		controller.clearErrors();
		controller.setData(action, val);
	};

	const viewError = () => {
		return controller.getStatus(action)?.validateStatus === 'error';
	};

	const viewHelperText = () => {
		const { validateStatus, help } = controller.getStatus(action) || {};

		return validateStatus === 'error' && help;
	};

	const viewErrorAlert = () => {
		return controller.getErrors();
	};

	return {
		isValue: getData(),
		isError: viewError(),
		isHelperText: viewHelperText(),
		isErrorAlert: viewErrorAlert(),
		setValue
	};
};

import { FormControl, Input, InputAdornment, IconButton } from '@mui/material';
import { ContentPasteGoOutlined } from '@mui/icons-material';

export const TextInputField = ({ placeholder, value, onChange, icon }) => {
	const pasteFromClipboard = () => {
		navigator.clipboard.readText().then((text) => onChange({ target: { value: text } }));
	};

	return (
		<FormControl fullWidth variant="outlined">
			<Input
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				startAdornment={<InputAdornment position="start">{icon}</InputAdornment>}
				endAdornment={
					<InputAdornment position="end">
						<IconButton onClick={pasteFromClipboard}>
							<ContentPasteGoOutlined />
						</IconButton>
					</InputAdornment>
				}
			/>
		</FormControl>
	);
};

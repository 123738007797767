import { useEffect, useMemo, useState } from 'react';
import { useUpdate } from 'ahooks';
import Controller from '../../../Libs/Controller';
import Rule from '../../../Libs/Rule';
import { AuthButton, EmailInput, PasswordInput } from '../../../Components/Auth';
import { Alert, Box } from '@mui/material';
import { useParams } from 'react-router';
import Net from '../../../Libs/Net';

export const ChangePass = ({ changePass }) => {
	const params = useParams();

	const [alert, setAlert] = useState('');

	const update = useUpdate();
	const controller = useMemo(() => {
		const c = new Controller(null, 'auth');
		c.setStructure({
			userLogin: '',
			userPassword: ''
		});
		c.clear();
		c.addObserver(update);
		c.addRule('userLogin', Rule.isEmail, 'Электронный адрес введен не корректно');
		c.addRule('userPassword', Rule.notEmpty, 'Пароль введен не корректно');
		c.addRule('newUserPassword', Rule.notEmpty, 'Пароль введен не корректно');
		return c;
	}, [update]);

	useEffect(() => {
		setAlert('');
	}, [controller.fields.userPassword, controller.fields.newUserPassword]);

	const resetPassword = () => {
		controller.deleteRule('userPassword');
		controller.deleteRule('newUserPassword');

		controller.checkData();
		if (controller.hasErrors()) return;

		Net.post('resetPassword', { userLogin: controller.getData('userLogin') })
			.then(() => {})
			.catch(() => {
				setAlert('Информация о смене пароля отправлена на почту');
			});
	};

	if (changePass) {
		const token = params?.token;
		const newPassword = controller.getData('newUserPassword');
		const userPassword = controller.getData('userPassword');

		const changePassword = (token) => {
			controller.deleteRule('userLogin');

			controller.checkData();
			if (controller.hasErrors()) return;

			if (newPassword !== userPassword) {
				return setAlert('Пароли не совпадают');
			}

			Net.post('changePassword', { userPassword: controller.getData('userPassword') }, null, {
				Authorization: token
			})
				.then(() => {
					document.location.href = '/';
				})
				.catch((err) => {
					setAlert(err);
				});
		};

		return (
			<Box
				component="form"
				sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.5rem' }}
			>
				<PasswordInput action="newUserPassword" controller={controller} label="Новый пароль" />
				<PasswordInput action="userPassword" controller={controller} label="Повторите пароль" />
				{alert && <Alert severity="error">{alert}</Alert>}
				<AuthButton onClick={() => changePassword(token)} controller={controller}>
					Сменить пароль
				</AuthButton>
			</Box>
		);
	}

	return (
		<Box
			component="form"
			sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.5rem' }}
		>
			<EmailInput action="userLogin" controller={controller} />
			{alert && <Alert severity="success">{alert}</Alert>}
			<AuthButton onClick={resetPassword} controller={controller}>
				Сменить пароль
			</AuthButton>
		</Box>
	);
};

import { useEffect, useMemo, useState } from 'react';
import { Button, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Config from '../../Config/Config';
import { useNavigate } from 'react-router';
import IvenoBus from '../../Libs/Iveno/IvenoBus';
import { offStateEmitID } from '../../Pages/Layout/MainHeader';
import Net from '../../Libs/Net';
import Session from '../../Config/Session';
import { userDataController } from '../../controllers/userDataController';
import { useUpdate } from 'ahooks';

const AvatarBlock = styled(IconButton)`
	margin-top: 1rem;
	position: relative;
`;

const AvatarStyle = styled(Avatar)`
	width: 74px;
	height: 74px;
	cursor: pointer;
`;

const createOutlinedIconStyle = {
	width: 18,
	height: 18,
	position: 'absolute',
	bottom: '0',
	right: '0',
	mr: '5px',
	mb: '5px'
};

const IconButtonStyle = styled(IconButton)`
	margin-left: auto;
	position: absolute;
	right: 0;
	top: 0;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
`;

const MenuStyle = {
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '1rem 3rem',
	gap: '0.2rem',
	borderRadius: '50px'
};

export const ProfileHeader = ({ size }) => {
	const [anchorEl, setAnchorEl] = useState(false);
	const navigate = useNavigate();

	const isSmall = size === 'small';

	const [userData, setUserData] = useState({});

	const { postName, authorName, avatarID } = userData || {};

	const update = useUpdate();

	const controller = useMemo(() => {
		return userDataController(update);
	}, [update]);

	useEffect(() => {
		controller
			.get({ id: Session.getAuthorID() })
			.then(() => {})
			.catch((err) => {});
		return () => {
			controller.removeObserver(update);
			controller.clear();
		};
	}, []);

	useEffect(() => {
		Net.post('getAuthorData', { id: Session.getAuthorID() })
			.then((res) => {
				setUserData(res);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		const id = IvenoBus.on(offStateEmitID, () => setAnchorEl(false));
		return () => IvenoBus.off(id);
	}, []);

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(false);
	};

	const TariffButtonStyle = styled(Typography)`
		position: relative;
		font-weight: bold;
		background-image: linear-gradient(45deg, #ff7200, #5c00ff);
		background-clip: text;
		-webkit-background-clip: text;
		color: transparent;
		cursor: pointer;
		user-select: none;

		:hover {
			background-image: linear-gradient(45deg, #fc8422, #8239ff);
		}
	`;

	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			MenuListProps={{
				sx: { ...MenuStyle }
			}}
			open={Boolean(anchorEl)}
			onClose={handleMenuClose}
			slotProps={{
				paper: {
					elevation: 0,

					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						borderRadius: '25px'
						// '&::before': {
						// 	content: '""',
						// 	display: isSmall ? 'none' : 'block',
						// 	position: 'absolute',
						// 	top: 0,
						// 	right: 12,
						// 	width: 10,
						// 	height: 10,
						// 	bgcolor: 'background.paper',
						// 	transform: 'translateY(-50%) rotate(45deg)',
						// 	zIndex: 0
						// }
					}
				}
			}}
		>
			<IconButtonStyle onClick={handleMenuClose}>
				<CloseIcon />
			</IconButtonStyle>
			<AvatarBlock onClick={() => navigate('/profile')}>
				<AvatarStyle src={avatarID?.Image2} />
				<CreateOutlinedIcon sx={createOutlinedIconStyle} />
			</AvatarBlock>
			<Typography style={{ userSelect: 'none', fontWeight: 700 }}>{authorName}</Typography>
			<Typography style={{ userSelect: 'none' }}>{postName}</Typography>
			<TariffButtonStyle onClick={() => navigate('/settings')}>
				{Session.getTariffName()}
			</TariffButtonStyle>
			<Button
				onClick={() => {
					Config.setToken(null);
				}}
				sx={{ textTransform: 'none', fontWeight: 700 }}
				startIcon={<LogoutIcon />}
				color="systemRed"
			>
				Выйти
			</Button>
		</Menu>
	);

	const isSize = isSmall ? { width: 24, height: 24 } : {};

	return (
		<>
			{isSmall ? (
				<MenuItem onClick={handleProfileMenuOpen}>
					<Avatar sx={isSize} src={avatarID?.Image2} />
					Профиль
				</MenuItem>
			) : (
				<IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
					<Avatar sx={isSize} src={avatarID?.Image2} />
				</IconButton>
			)}
			{renderMenu}
		</>
	);
};

import { useMemo } from 'react';
import { useUpdate } from 'ahooks';
import Controller from '../../../Libs/Controller';
import Rule from '../../../Libs/Rule';
import Config from '../../../Config/Config';
import { Box, Link, Typography } from '@mui/material';
import { AuthButton, EmailInput, PasswordInput, TextInput } from '../../../Components/Auth';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import axios from 'axios';
import { useLocation } from 'react-router';

export const Register = function ({ setMode }) {
	const update = useUpdate();
	const controller = useMemo(() => {
		const c = new Controller(null, 'register');
		c.setStructure({
			firstName: '',
			userLastName: '',
			accountName: '',
			userLogin: '',
			userPassword: '',
			postName: '',
			promoCode: ''
		});
		c.clear();
		c.addObserver(update);
		c.addRule('firstName', Rule.notEmpty, 'Укажите имя');
		c.addRule('lastName', Rule.notEmpty, 'Укажите фамилию');
		c.addRule('accountName', Rule.notEmpty, 'Укажите имя компании');
		c.addRule('postName', Rule.notEmpty, 'Укажите должность');
		c.addRule(
			'promoCode',
			Rule.isValidPromoCode,
			'Код должен состоять из заглавных латинских букв и цифр'
		);
		c.addRule('userLogin', Rule.isEmail, 'Электронный адрес введен не корректно');
		c.addRule('userPassword', Rule.notEmpty, 'Пароль введен не корректно');
		return c;
	}, [update]);

	const regPromo = (fields) => axios.post('https://pushkinbot.ru/webhook/reg_promo', fields);

	const queryParams = Object.fromEntries(new URLSearchParams(useLocation().search));

	const register = () => {
		const fieldsForPromo = { ...controller.fields, queryParams };

		controller
			.savePromise()
			.then((res) => {
				regPromo(fieldsForPromo)
					.catch(() => {})
					.finally(() => {
						Config.setToken(controller.getData('token'));
					});
			})
			.catch((err) => {});
	};

	return (
		<Box
			component="form"
			sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.5rem' }}
		>
			<TextInput
				action="firstName"
				controller={controller}
				icon={<Person2OutlinedIcon />}
				label="Имя"
			/>
			<TextInput
				action="lastName"
				controller={controller}
				icon={<AccountCircleOutlinedIcon />}
				label="Фамилия"
			/>
			<TextInput
				action="accountName"
				controller={controller}
				icon={<BusinessOutlinedIcon />}
				label="Название компании"
			/>
			<TextInput
				action="postName"
				controller={controller}
				icon={<WorkOutlineOutlinedIcon />}
				label="Ваша должность"
			/>
			<TextInput
				action="promoCode"
				controller={controller}
				icon={<PercentOutlinedIcon />}
				label="Промокод"
			/>
			<EmailInput action="userLogin" controller={controller} />
			<PasswordInput
				action="userPassword"
				controller={controller}
				label="Придумайте пароль"
				noComplete
			/>
			<AuthButton onClick={register} controller={controller}>
				Регистрация
			</AuthButton>
			<Typography color="systemGray.main" fontSize={10} align="center">
				Присоединяясь, вы соглашаетесь с
				<Link
					color="systemGray.main"
					href="https://www.gosrf.ru/rules"
					target="_blank"
					rel="noreferrer"
				>
					Правилами пользования сервисом
				</Link>
			</Typography>
		</Box>
	);
};

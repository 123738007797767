import { Box, CircularProgress, Modal, styled } from '@mui/material';
import { useEffect, useState } from 'react';

const BoxStyle = styled(Box)`
	position: absolute;
	background: white;
	width: 140px;
	height: 140px;
	top: 50%;
	left: 50%;
	z-index: 500;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	border-radius: 25px;
`;

export const ModalProgress = ({ openModal, setOpenModal }) => {
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 20));
		}, 800);

		return () => {
			clearInterval(timer);
		};
	}, []);

	const handleClose = (event, reason) => {
		if (reason === 'backdropClick') {
			return;
		}
		setOpenModal(false);
	};
	return (
		<Modal disableBackdropClick disableEscapeKeyDown open={openModal} onClose={handleClose}>
			<BoxStyle>
				<Box
					sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '1rem' }}
				>
					{/* <CircularProgress size="70px" variant="determinate" value={progress} /> */}
					<CircularProgress size="70px" />
					Публикуем...
				</Box>
			</BoxStyle>
		</Modal>
	);
};

import React, { useEffect, useState } from 'react';
import * as VKID from '@vkid/sdk';
import { webhook } from '../../SocialPage/webhook';

export const VKIDLoginButton = () => {
	const [codeVerifier, setCodeVerifier] = useState(null);

	useEffect(() => {
		webhook
			.get('vk_oauth')
			.then((res) => {
				setCodeVerifier(res);
			})
			.catch((err) => console.error(err));
	}, []);

	useEffect(() => {
		if (!codeVerifier) {
			return;
		}

		const initVKID = async () => {
			VKID.Config.init({
				app: '52382368',
				redirectUrl: 'https://govorun.pushkinbot.ru/oauht/',
				state: codeVerifier?.state,
				codeVerifier: codeVerifier?.code_verifier,
				scope: 'wall groups offline photos audio video docs',
				codeChallengeMethod: 's256'
			});

			const oneTap = new VKID.OneTap();

			const container = document.getElementById('VkIdSdkOneTap');

			if (container) {
				oneTap
					.render({
						container: container,
						scheme: VKID.Scheme.LIGHT,
						lang: VKID.Languages.RUS
					})
					.on(VKID.WidgetEvents.ERROR, (error) => {
						console.error('VKID Widget Error:', error);
					})
					.on(VKID.WidgetEvents.SUCCESS, (data) => {
						console.log('VKID Login Success:', data);
					});
			}
		};
		initVKID();
	}, [codeVerifier]);

	return <div id="VkIdSdkOneTap" />;
};

import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { SearchHeader } from '../../Components/SearchHeader/SearchHeader';
import { ProfileHeader } from '../../Components/ProfileHeader/ProfileHeader';
import { ReactComponent as Logo } from '../../Assets/Logo.svg';
import { ReactComponent as PushkinIcon } from '../../Assets/icons/button-pushkin.svg';
import { ReactComponent as PushkinIconMobile } from '../../Assets/Components/pushkin_assets/pushkin_mob.svg';
import IvenoBus from '../../Libs/Iveno/IvenoBus';
import { Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { openChatGPTDrawer } from '../SimpleArticleEdit/ChatGptDrawer/ChatGPTDrawer';
import { useScroll } from 'ahooks';
import { DropdownButton, HelpButtonHeader } from '../../Components/DropdownButton/DropdownButton';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';

export const handleLeftDrawerToggle = (closeDrawer) => {
	const { payload } = IvenoBus.get('updateState') || {};

	if (!payload) return;

	const emitState = (action) => IvenoBus.emit('updateState', { ...payload, ...action });

	if (closeDrawer === true) {
		emitState({
			activeMobile: false
		});
	} else {
		emitState({
			active: payload.disable ? payload.active : !payload.active,
			activeMobile: payload.disable ? !payload.activeMobile : payload.activeMobile
		});
	}
};

export const offStateEmitID = 'offStateEmitID';

export const MainHeader = () => {
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(false);
	const [isViewSearch, setIsViewSearch] = useState(false);
	const isMiddleScreen = useMediaQuery('(min-width:600px)');
	const [atStartOfPage, setAtStartOfPage] = useState(0);
	const theme = useTheme();
	const navigate = useNavigate();

	const { top } = useScroll(document) || {};
	const trigger = useScrollTrigger({ threshold: 1 });

	useEffect(() => {
		isMiddleScreen && setIsViewSearch(false);
	}, [isMiddleScreen]);

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	useEffect(() => {
		const id = IvenoBus.on(offStateEmitID, () => setMobileMoreAnchorEl(false));
		return () => IvenoBus.off(id);
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const handleScroll = () => {
				if (window.scrollY === 0) {
					setAtStartOfPage(0);
				} else {
					setAtStartOfPage(4);
				}
			};

			window.addEventListener('scroll', handleScroll);

			return () => window.removeEventListener('scroll', handleScroll);
		}
	}, [atStartOfPage]);

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const hideAppBar = () => {
		if (top >= 100 && trigger && !isMiddleScreen) {
			return 'translateY(-100%)';
		}
	};

	const dropdownData = [
		{
			label: 'Поддержка',
			icon: <LiveHelpOutlinedIcon />,
			link: 'https://t.me/rrsupbot'
		},
		{
			label: 'Видео-инструкция',
			icon: <VideoLibraryOutlinedIcon />,
			link: 'https://vk.com/video/playlist/-28883769_1'
		}
	];

	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
			sx={{
				li: {
					padding: '1rem 1rem',
					display: 'flex',
					gap: '.5rem'
				}
			}}
		>
			<DropdownButton icon={<HelpOutlineIcon />} label="Помощь" data={dropdownData} />

			<MenuItem onClick={() => navigate('/settings')}>
				<SettingsOutlinedIcon />
				Настройки
			</MenuItem>
			<ProfileHeader size="small" />
		</Menu>
	);

	return (
		<Box
			sx={{
				flexGrow: 1,
				paddingBottom: atStartOfPage === 4 && '96px',
				zIndex: mobileMoreAnchorEl ? 20 : 18
			}}
		>
			<AppBar
				color="background"
				position={atStartOfPage ? 'fixed' : 'relative'}
				elevation={atStartOfPage}
				sx={{
					padding: '1rem 0',
					transform: hideAppBar(),
					transition: 'transform 225ms'
				}}
			>
				<Toolbar
					sx={{
						'&.MuiToolbar-root': {
							padding: 0
						}
					}}
				>
					<Box
						minWidth={{ sm: '200px' }}
						sx={{
							display: 'flex',
							alignItems: 'center',
							width: '20%',
							paddingLeft: '1rem',
							position: 'relative'
						}}
					>
						<IconButton
							onClick={handleLeftDrawerToggle}
							size="large"
							edge="start"
							color="inherit"
							sx={{ mr: 1 }}
						>
							<MenuIcon />
						</IconButton>
						<Button
							onClick={() => navigate('/')}
							sx={{
								borderRadius: '15px',
								color: 'primary.contrastText',
								minWidth: '52px',
								height: '52px',
								padding: '0',
								'&:active': {
									color: 'none'
								}
							}}
						>
							<Logo />
						</Button>
						<Typography
							variant="h5"
							component="div"
							sx={{
								fontWeight: 'bold',
								ml: 2,
								userSelect: 'none',

								'&::after': {
									position: 'absolute',
									content: '"β"',
									fontSize: '.6em',
									bottom: '3px'
								}
							}}
						>
							Пиар
						</Typography>
					</Box>
					{isMiddleScreen ? <SearchHeader /> : <Box sx={{ flexGrow: 1 }} />}
					<Box
						sx={{ display: { xs: 'none', md: 'flex' }, paddingRight: '1rem' }}
						style={{
							alignItems: 'center',
							justifyContent: 'end',
							width: '20%',
							minWidth: '280px'
						}}
					>
						<IconButton
							sx={{
								borderRadius: theme.customShare.borderRadius
							}}
							size="large"
							color="inherit"
							onClick={() => openChatGPTDrawer()}
						>
							<PushkinIcon style={{ borderRadius: theme.customShare.borderRadius }} />
						</IconButton>
						<DropdownButton icon={<HelpOutlineIcon />} data={dropdownData} />
						<IconButton onClick={() => navigate('/settings')} size="large" color="inherit">
							<SettingsOutlinedIcon />
						</IconButton>
						<ProfileHeader />
					</Box>
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<IconButton
							onClick={() => openChatGPTDrawer()}
							sx={{
								borderRadius: theme.customShare.borderRadius,
								padding: '0 10px'
							}}
							size="large"
							// color="inherit"
						>
							<PushkinIconMobile
								style={{
									borderRadius: theme.customShare.borderRadius,
									width: 28,
									height: 28
								}}
							/>
						</IconButton>
						{!isMiddleScreen && (
							<IconButton
								sx={{
									borderRadius: theme.customShare.borderRadius
								}}
								size="large"
								color="inherit"
							>
								<SearchOutlinedIcon
									onClick={() => setIsViewSearch(!isViewSearch)}
									style={{ borderRadius: theme.customShare.borderRadius }}
								/>
							</IconButton>
						)}
						<IconButton size="large" onClick={handleMobileMenuOpen} color="inherit">
							<MoreIcon />
						</IconButton>
					</Box>
				</Toolbar>
				{isViewSearch && <SearchHeader style={{ margin: '1rem 1rem 0 1rem' }} />}
			</AppBar>
			{renderMobileMenu}
		</Box>
	);
};

import React, { useEffect, useState } from 'react';
import { SignIn } from './Sections/SignIn';
import { Register } from './Sections/Register';
import {
	Alert,
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	ThemeProvider,
	Typography,
	createTheme,
	styled
} from '@mui/material';
import Theme from '../../Config/Theme';
import { ActionButton, AuthLogo } from '../../Components/Auth';
import { ChangePass } from './Sections/ChangePass';
import { useLocation, useNavigate } from 'react-router';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import Dialog from '@mui/material/Dialog';

const theme = createTheme(Theme);

const AuthPageStyle = styled(Box)(({ theme }) => ({
	width: '100vw',
	minHeight: '100vh',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	background: theme.palette.systemBlue.main,
	padding: '0 2rem 0 2rem',

	'@media (max-width: 470px)': {
		padding: '0 1rem 0 1rem'
	}
}));

const AuthBlock = styled(Box)`
	position: relative;
	border-radius: 35px;
	background-color: #ffffff;
	padding: 40px 40px 10px 40px;
	box-shadow: 0 4px 12.9px 0 rgba(0, 64, 138, 0.5);
	min-width: 450px;

	@media (max-width: 470px) {
		min-width: 90%;
		padding-right: 20px;
		padding-left: 20px;
	}
`;

const AUTH_MODES = {
	REGISTER: 'REGISTER',
	SIGNIN: 'SIGNIN',
	CHANGEPASS: 'CHANGEPASS'
};

const AuthPage = ({ isChangePass }) => {
	const { REGISTER, SIGNIN, CHANGEPASS } = AUTH_MODES;

	const [open, setOpen] = useState(false);
	const [mode, setMode] = useState(SIGNIN);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const register = () => {
		navigate('/reg');
		setMode(REGISTER);
	};
	const signIn = () => {
		navigate('/');
		setMode(SIGNIN);
	};
	const changePass = () => setMode(CHANGEPASS);

	useEffect(() => {
		if (pathname === '/reg') {
			setMode(REGISTER);
		}

		if (window.location.hostname === 'prdev.gosrf.ru') {
			setOpen(true);
		}

		if (isChangePass) {
			changePass();
		}
	}, []);

	const AuthComponents = [
		{ elMode: REGISTER, component: <Register /> },
		{ elMode: SIGNIN, component: <SignIn setModeChange={changePass} setMode={register} /> },
		{ elMode: CHANGEPASS, component: <ChangePass changePass={isChangePass} /> }
	];

	return (
		<ThemeProvider theme={theme}>
			<AuthPageStyle>
				<Dialog open={open} sx={{ p: '1rem' }}>
					<DialogTitle align="right" sx={{ p: '8px 8px 0 0' }}>
						<CloseOutlinedIcon
							sx={{ cursor: 'pointer' }}
							color="disabled"
							onClick={() => setOpen(false)}
						/>
					</DialogTitle>
					<DialogContent sx={{ p: 0 }}>
						<Alert sx={{ display: 'flex', alignItems: 'center' }} severity="warning">
							Вы используете устаревшую версию РР Пиар. <br /> Актуальная версия находится по
							адресу:{' '}
							<a style={{ color: '#2780E8' }} href="https://pr.gosrf.ru/">
								pr.gosrf.ru
							</a>
						</Alert>
					</DialogContent>
					<DialogActions
						sx={{
							justifyContent: 'center'
						}}
					>
						<Button
							startIcon={<OpenInNewOutlinedIcon />}
							variant="contained"
							onClick={() => (window.location.href = 'https://pr.gosrf.ru/')}
						>
							Перейти
						</Button>
					</DialogActions>
				</Dialog>
				{mode === REGISTER && (
					<Box
						sx={{
							width: '50%',
							alignSelf: 'flex-start',
							margin: '10rem 5rem 0 0',
							'@media (max-width: 900px)': {
								display: 'none'
							}
						}}
					>
						<Typography variant="h2" color="white">
							AI сервис для пресс-служб, бизнеса и авторов
						</Typography>
					</Box>
				)}
				<AuthBlock>
					{mode === REGISTER && (
						<ActionButton
							style={{
								position: 'absolute',
								top: '1rem',
								right: '1rem'
							}}
							onClick={signIn}
						>
							Войти
						</ActionButton>
					)}
					<AuthLogo theme={theme} />
					{AuthComponents.map(({ elMode, component }) => (
						<React.Fragment key={elMode}> {mode === elMode && component}</React.Fragment>
					))}
				</AuthBlock>
			</AuthPageStyle>
		</ThemeProvider>
	);
};

export default AuthPage;

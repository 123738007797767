import { Box, IconButton } from '@mui/material';
import { CloseOutlined, LiveHelpOutlined } from '@mui/icons-material';

export const ModalHeader = ({ handleClose }) => (
	<Box sx={{ display: 'flex', justifyContent: 'end', width: '100%', p: '1rem 0' }}>
		<IconButton href="https://t.me/rrsupbot" target="_blank">
			<LiveHelpOutlined />
		</IconButton>
		<IconButton onClick={handleClose}>
			<CloseOutlined />
		</IconButton>
	</Box>
);

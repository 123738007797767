import { useEffect } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useValidate } from '../../hooks/useValidate';

export const TextInput = ({
	controller,
	action,
	type = 'text',
	icon,
	label,
	variant = 'standard',
	defaultValue
}) => {
	const { isValue, setValue, isError, isHelperText } = useValidate(controller, action);

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	return (
		<TextField
			type={type}
			fullWidth
			label={label}
			variant={variant}
			value={isValue}
			onChange={(e) => setValue(e.target.value)}
			disabled={controller.loading}
			error={isError}
			helperText={isHelperText}
			autoFocus
			InputProps={{
				startAdornment: icon && <InputAdornment position="start">{icon}</InputAdornment>
			}}
		/>
	);
};

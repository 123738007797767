import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import Net from '../../Libs/Net';
import { Link } from 'react-router-dom';

const SearchWrap = styled('div')`
	flex-grow: 1;
`;

const Search = styled('div')(({ theme }) => ({
	position: 'relative',
	borderRadius: theme.customShare.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 1),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 1)
	},
	margin: '0 auto',
	[theme.breakpoints.up('sm')]: {
		width: '90%'
	}
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center'
}));

const AutocompleteStyle = styled(Autocomplete)(({ theme }) => ({
	height: '100%',
	paddingLeft: `calc(1em + ${theme.spacing(4)})`
}));

export const SearchHeader = ({ style }) => {
	const [searchItem, setSearchItem] = useState([]);
	const [value, setValue] = useState(true);

	useEffect(() => {
		Net.post('getArticlesForModerate', {}).then((res) => {
			setSearchItem(
				res.items.map((el) => ({ id: el.id, title: el.title && el.title.toString() }))
			);
		});
	}, []);

	useEffect(() => setValue(true), [value]);

	return (
		<SearchWrap style={style}>
			<Search>
				<SearchIconWrapper>
					<SearchIcon />
				</SearchIconWrapper>
				<AutocompleteStyle
					freeSolo
					disableClearable
					options={searchItem.map((option) => ({ id: option.id, title: option.title }))}
					getOptionKey={(option) => option.id}
					getOptionLabel={(option) => (value ? option.title : '')}
					renderInput={(params) => (
						<>
							<TextField
								sx={{
									'.MuiOutlinedInput-notchedOutline': { border: 'none' },
									'.MuiInputLabel-root': { color: 'gray' },
									'.MuiOutlinedInput-root': { padding: '0' }
								}}
								{...params}
								placeholder="Поиск по материалам"
							/>
						</>
					)}
					renderOption={(props, option) => (
						<li key={option.id} {...props}>
							<Link
								style={{
									display: 'block',
									width: '100%',
									textDecoration: 'none',
									color: '#121212'
								}}
								to={`/${option.id}`}
								onClick={() => setValue(false)}
							>
								{option.title}
							</Link>
						</li>
					)}
					filterOptions={(options, params) => {
						const filtered = options.filter((option) => {
							if (typeof option.title === 'string') {
								return option.title.toLowerCase().includes(params.inputValue.toLowerCase());
							}
							return false;
						});

						if (filtered.length > 5) {
							return filtered.slice(0, 5);
						}

						return filtered;
					}}
				/>
			</Search>
		</SearchWrap>
	);
};

import _ from 'underscore';
import { useEffect, useState } from 'react';
import Net from '../../Libs/Net';
import Utils from '../../Libs/Utils';
import { ArticleItem } from './ArticleItem';
import { LoadingAnchor } from './LoadingAnchor';
import { Link, useLocation } from 'react-router-dom';
import img404 from '../../Assets/img/404.png';
import { Button, styled } from '@mui/material';
import { webhook } from '../SocialPage/webhook';

const ArticlesPageStyle = styled('div')`
	.articlesHeader {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.articlesWrapper {
		display: flex;
		width: 100%;
		gap: 20px;
		align-items: flex-start;
		padding-bottom: 1rem;
	}
`;

export const ArticlesList = styled('div')`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
	width: 100%;
	gap: 1rem;

	@media (min-width: 1300px) {
		grid-template-columns: repeat(3, minmax(220px, 1fr));
	}
`;

const defaultLimit = 20;

const getDefaults = () => {
	let arr = [];
	for (let i = 0; i < defaultLimit; i++) {
		arr.push({ id: i });
	}
	return arr;
};

const ArticlesPage = () => {
	const [loading, setLoading] = useState(true);
	const [loaded, setLoaded] = useState(false);
	const [items, setItems] = useState(getDefaults());
	const [socialItems, setSocialItems] = useState([]);
	const [count, setCount] = useState(0);
	const [limit] = useState(defaultLimit);
	const [offset, setOffset] = useState(0);
	const [oldFilter, setOldFilter] = useState({});

	const { pathname } = useLocation();

	// useEffect(() => {

	// }, []);

	useEffect(() => {
		if (pathname === '/') applyFilter({ status: 5, isPublished: true });
		if (pathname === '/drafts') applyFilter({ status: 1 });
		if (pathname === '/moderation') applyFilter({ isPublished: false });
		if (pathname === '/mailing' || pathname === '/site') {
			setItems(0);
			setLoaded(false);
		}
	}, [pathname]);

	const load = (_offset, _flt) => {
		setLoading(true);
		if (_offset === undefined) {
			_offset = offset;
		}
		if (_flt === undefined && !Utils.isEmpty(oldFilter)) {
			_flt = oldFilter;
		}

		if (!Utils.isEmpty(_flt)) {
			setOldFilter(_flt);
		}
		let filter = {
			offset: _offset,
			limit: limit,
			filter: _flt || {}
		};
		if (_flt) {
			if (!Utils.isEmpty(_flt['str'])) {
				filter['str'] = _flt['str'];
			}
		}

		Net.post('getArticlesForModerate', filter)
			.then(async (res) => {
				let newItems = _offset === 0 ? res.items : items.concat(res.items);
				const socItems = await webhook.get('ssPosts');

				const filteredSocItems = socItems.length
					? filter.filter.status === 5
						? socItems.filter((item) => item.status === 'publish' || item.status === 'delay')
						: filter.filter.status === 1
						? socItems.filter((item) => item.status === 'draft')
						: []
					: [];

				const allItems = newItems.concat(filteredSocItems);
				const sortedItems = _.sortBy(allItems, (item) => new Date(item.pubDate)).reverse();

				setItems(sortedItems);
				setCount(res.count);
				setLoading(false);
				setLoaded(true);
				setOffset(_offset + _.size(res.items));
			})
			.catch(() => {});
	};

	const applyFilter = (filter) => {
		setItems(getDefaults());
		setCount(0);
		setLoaded(false);
		load(0, filter);
	};

	const pageInfo = ((path) => {
		const cleanedPath = path.replace(/\//g, '');
		const content = {
			main: 'Публикации пока не созданы',
			moderation: 'Жду ваших публикаций',
			mailing: 'Сервис находится в разработке',
			social: 'Сервис находится в разработке',
			site: (
				<Button
					sx={{ textTransform: 'none' }}
					onClick={() => window.open('https://t.me/rrsupbot')}
					variant="contained"
				>
					Добавить мой сайт
				</Button>
			)
		};

		return content.hasOwnProperty(cleanedPath) ? content[cleanedPath] : content.main;
	})(pathname);

	return (
		<ArticlesPageStyle>
			<div className="articlesWrapper">
				{_.isEmpty(items) ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%'
						}}
					>
						{pathname !== '/site' && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'end',
									margin: '0 auto',
									gap: '1rem',
									width: '50%',
									minWidth: '250px',
									aspectRatio: '1/1',
									background: `no-repeat url('${img404}')`,
									backgroundPosition: 'center',
									backgroundSize: 'cover'
								}}
							/>
						)}

						<span
							style={{
								fontSize: '1.5rem',
								marginBottom: '-1rem',
								textShadow: '1px 1px 1px #bbb',
								textAlign: 'center'
							}}
						>
							{pageInfo}
						</span>
					</div>
				) : (
					<ArticlesList length={items.length}>
						{_.map(items, (item) => {
							return (
								<Link style={{ textDecoration: 'none' }} to={'/' + item.id} key={item.id}>
									<ArticleItem article={item} loading={loading} setItems={setItems} />
								</Link>
							);
						})}
					</ArticlesList>
				)}
			</div>
			{loaded && (
				<LoadingAnchor loading={loading} loadFunction={load} needLoad={_.size(items) < count} />
			)}
		</ArticlesPageStyle>
	);
};

export default ArticlesPage;

import { Box, Typography } from '@mui/material';
import { VKIDLoginButton } from '../../../../SimpleArticleEdit/Components/VKIDLoginButton';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';

export const Vk = () => (
	<Box className="text-block">
		<Typography
			variant="h5"
			fontWeight={700}
			sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
		>
			<LibraryAddOutlinedIcon /> Добавление VK
		</Typography>

		<Box sx={{ width: '100%' }}>
			<VKIDLoginButton />
		</Box>
	</Box>
);

function getColorVariable(name) {
	const computedStyle = getComputedStyle(document.documentElement);
	return computedStyle.getPropertyValue(`--${name}`).trim();
}

const colorPrimary = getColorVariable('color-primary'); // #dd2c34;
const colorPrimaryOpacity = getColorVariable('color-primary-opacity'); // #f78381;
const colorWhite = getColorVariable('color-white'); // #ffffff;
const colorText = getColorVariable('color-text'); // #121111;
const colorTextLight = getColorVariable('color-text-light'); // #dd2c3444;
const colorGray = getColorVariable('color-gray'); // #b3b3b3;
const colorBackground = getColorVariable('color-background'); // #e9e9e9;
const colorDarkBackground = getColorVariable('color-dark-background'); // #768594;
const colorBlack = getColorVariable('color-black'); // #12111;
const colorGreen = getColorVariable('color-green'); // #52c41a;

const Theme = {
	// breakpoints: {
	// 	values: {
	// 	  xs: 0,
	// 	  sm: 500,
	// 	  md: 960,
	// 	  lg: 1280,
	// 	  xl: 1920,
	// 	},
	//   },

	// breakpoints: {
	// 	values: {
	// 	  mobile: 0,
	// 	  tablet: 640,
	// 	  laptop: 1024,
	// 	  desktop: 1200,
	// 	},
	//   },

	palette: {
		primary: {
			main: '#2780E8'
		},
		foreground: {
			main: colorText // Основной шрифт редактора, черный
		},
		background: {
			main: colorBackground // Фон, светло-серый
		},
		systemRed: {
			main: '#E2635C', // Системный красный, акцентный цвет
			secondary: '#E9E9E9'
		},
		systemBlue: {
			main: '#2780E8' // Системный синий
		},
		systemGray: {
			main: '#B3B3B3' // Некоторые системные надписи, подсказки, серый
		},
		divider: {
			main: '#4D4D4D' // надписи, некоторые иконки, разделители
		},
		text: {
			primary: '#121111', // Основной цвет текста
			secondary: '#B3B3B3' // Второстепенный цвет текста
		}
	},

	customShare: {
		borderRadius: '100px'
	}
};

export default Theme;

import { Outlet } from 'react-router';
import { MainHeader } from './MainHeader';
import { MainContent } from './MainContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, styled } from '@mui/material';
import Theme from '../../Config/Theme';

const theme = createTheme(Theme);

const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	height: '100vh'
});

export const MainLayout = () => (
	<ThemeProvider theme={theme}>
		<Wrapper>
			<MainHeader />
			<MainContent>
				<Outlet />
			</MainContent>
		</Wrapper>
	</ThemeProvider>
);

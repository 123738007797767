import { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { downloadImageAsBase64 } from './downloadImgAsBase64';
import _ from 'underscore';
import DataUtils from '../../../Libs/DataUtils';

const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
	return centerCrop(
		makeAspectCrop(
			{
				unit: 'px',
				width: mediaWidth * 0.9
			},
			aspect,
			mediaWidth,
			mediaHeight
		),
		mediaWidth,
		mediaHeight
	);
};

export const ImageCropper = ({ imageToCrop, setFile }) => {
	const [cropConfig, setCropConfig] = useState({});
	const [isImage, setIsImage] = useState(false);

	const imageRef = useRef(null);

	useEffect(() => {
		if (DataUtils.isUrl(imageToCrop)) {
			downloadImageAsBase64(imageToCrop)
				.then((res) => setIsImage(res))
				.catch((err) => {});
		} else {
			setIsImage(imageToCrop);
		}
	}, [imageToCrop]);

	// useEffect(() => {
	// 	requestAnimationFrame(() => {
	// 		const { width, height } = imageRef.current;
	// 		setCropConfig(centerAspectCrop(width, height, 16 / 9));
	// 	});
	// }, [isImage]);

	useEffect(() => {
		if (!imageRef.current) return;

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const { width, height } = imageRef.current;
					setCropConfig(centerAspectCrop(width, height, 16 / 9));
					observer.disconnect();
				}
			});
		});

		observer.observe(imageRef.current);

		return () => {
			if (observer && observer.disconnect) {
				observer.disconnect();
			}
		};
	}, [isImage]);

	useEffect(() => {
		if (cropConfig && cropConfig.width && cropConfig.height) {
			cropImage(cropConfig);
		}
	}, [cropConfig]);

	const cropImage = async (crop) => {
		try {
			if (imageRef.current && crop.width && crop.height) {
				const cropped = await getCroppedImage(imageRef.current, crop, 'croppedImage.jpeg');
				setFile(cropped.file);
				// onImageCropped(cropped.croppedImageUrl);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getCroppedImage = async (sourceImage, cropConfig, fileName, quality = 1) => {
		const canvas = document.createElement('canvas');

		const scaleX = sourceImage.naturalWidth / sourceImage.width;
		const scaleY = sourceImage.naturalHeight / sourceImage.height;

		canvas.width = cropConfig.width * scaleX;
		canvas.height = cropConfig.height * scaleY;

		const ctx = canvas.getContext('2d');

		ctx.drawImage(
			sourceImage,
			cropConfig.x * scaleX,
			cropConfig.y * scaleY,
			cropConfig.width * scaleX,
			cropConfig.height * scaleY,
			0,
			0,
			cropConfig.width * scaleX,
			cropConfig.height * scaleY
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob(
				(blob) => {
					if (!blob) {
						reject(new Error('Canvas is empty'));
						return;
					}

					blob.name = fileName;

					const file = new File([blob], blob.name, {
						type: blob.type,
						lastModified: blob.lastModified ? new Date(blob.lastModified) : undefined
					});

					const fileObject = {
						name: file.name,
						size: file.size,
						type: file.type,
						webkitRelativePath: '',
						lastModified: file.lastModified,
						lastModifiedDate: new Date(file.lastModified)
					};

					const croppedImageUrl = window.URL.createObjectURL(file);

					resolve({ croppedImageUrl, fileObject, blob, file });
				},
				'image/jpeg',
				quality
			);
		});
	};

	return (
		<ReactCrop
			// style={{ margin: '1rem 0' }}
			aspect={16 / 9}
			keepSelection
			crop={cropConfig}
			ruleOfThirds
			onComplete={(cropConfig) => cropImage(cropConfig)}
			onChange={(cropConfig) => setCropConfig(cropConfig)}
		>
			<img alt="" ref={imageRef} src={isImage} />
		</ReactCrop>
	);
};

import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { useInViewport } from 'ahooks';
import _ from 'underscore';

const LoadingAnchorStyle = styled.div`
	/* position: absolute; */
	bottom: 0;
	left: 0;
	width: 1px;
	height: 1px;
	display: flex;
	align-items: flex-end;
	margin: 0 auto;

	.anchor {
		/* height: 250px; */
	}

	.loadingElement {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;

		.dotsWrapper {
			width: 90px;
			display: flex;
			align-items: center;
			justify-content: space-around;

			svg {
				circle {
					fill: var(--color-text);
				}
			}

			svg:nth-child(1) {
				animation-name: bubble-loading;
				animation-duration: 1.2s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
			}

			svg:nth-child(2) {
				animation-name: bubble-loading;
				animation-duration: 1.2s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
				animation-delay: 0.6s;
			}

			svg:nth-child(3) {
				animation-name: bubble-loading;
				animation-duration: 1.2s;
				animation-iteration-count: infinite;
				animation-timing-function: ease-in-out;
				animation-delay: 0.8s;
			}
		}
	}

	@keyframes bubble-loading {
		from {
			width: 10px;
			height: 10px;
		}
		50% {
			width: 20px;
			height: 20px;
		}
		to {
			width: 10px;
			height: 10px;
		}
	}
`;

export const LoadingAnchor = function ({ loading, loadFunction, needLoad }) {
	const ref = useRef(null);
	const [inViewport] = useInViewport(ref);

	useEffect(() => {
		if (needLoad && inViewport && !loading) {
			if (_.isFunction(loadFunction)) {
				loadFunction();
			}
		}
	}, [inViewport]);

	return (
		<LoadingAnchorStyle>
			{loading ? (
				<div className="loadingElement">
					<div className="dotsWrapper">
						<svg
							width="10"
							height="10"
							viewBox="0 0 10 10"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="5" cy="5" r="5" fill="#155ABE" />
						</svg>
						<svg
							width="10"
							height="10"
							viewBox="0 0 10 10"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="5" cy="5" r="5" fill="#155ABE" />
						</svg>
						<svg
							width="10"
							height="10"
							viewBox="0 0 10 10"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="5" cy="5" r="5" fill="#155ABE" />
						</svg>
					</div>
				</div>
			) : (
				<div className="anchor" ref={ref} />
			)}
		</LoadingAnchorStyle>
	);
};

import { Box, Button, Typography } from '@mui/material';
import { Telegram as TelegramIcon } from '@mui/icons-material';
import { TextInputField } from '../TextInputField';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { useNotification } from '../../../../../hooks/useNotification';

export const Step2 = ({ setStep, inputText, setInputText }) => {
	const { error } = useNotification();
	const handleNext = () => {
		if (!inputText.channelUrl.trim()) {
			return error('Поле не может быть пустым');
		}

		setStep(3);
	};

	return (
		<Box className="text-block">
			<Typography
				variant="h5"
				fontWeight={700}
				sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
			>
				<LibraryAddOutlinedIcon /> Добавление Telegram
			</Typography>
			<Typography variant="body1" textAlign="center" fontWeight={700}>
				2 шаг
				<br />
				Укажите URL вашего канала
			</Typography>
			<TextInputField
				placeholder="Укажите URL канала в формате https://t.me/мой_канал"
				value={inputText.channelUrl}
				onChange={(e) => setInputText({ ...inputText, channelUrl: e.target.value })}
				icon={<TelegramIcon sx={{ color: 'systemBlue.main' }} />}
				field="channelUrl"
			/>
			<Button onClick={handleNext} variant="contained">
				Далее
			</Button>
		</Box>
	);
};

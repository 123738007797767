import React, { useEffect, useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

import { articlesRegController } from '../../controllers/articlesRegController';

import { useUpdate } from 'ahooks';
import { usePublish } from '../../hooks/usePublish';

import { useStylesButton } from './useStylesButton';
import { useButtonMode } from './useButtonMode';
import _ from 'underscore';
import { ModalProgress } from './ModalProgress';

export const CreationButton = () => {
	const [open, setOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const controller = articlesRegController();
	const update = useUpdate();

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { getSpeedDialActionStyle, getSpeedDialStyle } = useStylesButton(open);
	const { icon, color, actions, mode } = useButtonMode(controller, pathname);

	const publish = usePublish(controller, open, setOpen, setOpenModal, mode);

	useEffect(() => controller.addObserver(update), []);

	const handleClick = (action) => {
		if (_.isFunction(action)) {
			action();
		} else if (action.split('_')[0] === 'WEB') {
			publish(null, action);
		} else {
			navigate(action);
		}
		setOpen(false);
	};

	return (
		<>
			{openModal && <ModalProgress openModal={openModal} setOpenModal={setOpenModal} />}

			<Box
				onMouseLeave={() => setOpen(false)}
				sx={{
					height: 0,
					position: 'fixed',
					width: '100vw',
					bottom: 0
				}}
			>
				<SpeedDial
					style={{ borderRadius: '2px' }}
					sx={getSpeedDialStyle(color)}
					open={open}
					onClick={publish}
					icon={icon}
					ariaLabel="Создать материал"
				>
					{actions.map((action) => (
						<SpeedDialAction
							key={action.name}
							sx={getSpeedDialActionStyle(action)}
							icon={action.icon}
							onClick={() => handleClick(action.action)}
						/>
					))}
				</SpeedDial>
			</Box>
		</>
	);
};

import PersonIcon from '@mui/icons-material/Person';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import TelegramIcon from '@mui/icons-material/Telegram';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { eventsID } from '../../Pages/SocialPage/SocialPage';
import Iveno from '../../Libs/Iveno/Iveno';
import { ReactComponent as VK } from '../../Assets/Logo/vk_logo.svg';
import { ReactComponent as OK } from '../../Assets/Logo/ok_logo.svg';

export const actionsData = {
	defaultActions: [
		{ icon: <PersonIcon />, name: 'Колонка', action: 'column', articleType: 3 },
		{ icon: <TextSnippetOutlinedIcon />, name: 'Статья', action: 'new', articleType: 1 },
		{ icon: <PhoneAndroidOutlinedIcon />, name: 'Пост', action: 'social', articleType: 4 }
	],
	socialActions: [
		{
			icon: <TelegramIcon />,
			name: 'Telegram',
			action: () => {
				Iveno.Bus.emit(eventsID.tg);
			}
		},
		{
			icon: <VK style={{ width: '24px', height: '24px' }} />,
			name: 'VK',
			action: () => {
				Iveno.Bus.emit(eventsID.vk);
			}
		},
		{
			icon: <OK style={{ width: '24px', height: '24px' }} />,
			name: 'OK',
			action: () => {
				Iveno.Bus.emit(eventsID.ok);
			}
		}
	],

	socialActionsSend: [
		{
			icon: <SendOutlinedIcon />,
			name: 'Опубликовать',

			action: 'WEB_PUBLISH'
		},
		{
			icon: <SaveOutlinedIcon />,
			name: 'Сохранить',
			action: 'WEB_CREATE'
		}
	]
};

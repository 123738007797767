import axios from 'axios';
import Session from '../../Config/Session';
import { userDataController } from '../../controllers/userDataController';

const config = {
	url: 'https://pushkinbot.ru/webhook',
	username: 'gosrfssrr',
	password: 'ehbfq8374fubwe46gfomq9210re74gbwjkms745',
	prompt:
		'На основе этого текста напиши пост для соцсетей. Пост должен быть не более 900 знаков (строго запрещено создавать тексты более 990 знаков с пробелами). Обязательно раздели текст на абзацы. Тебе запрещено использовать смайлики и хэштеги. Для форматирования текста используй html разметку',
	token: 'nKD5pgshc30G3Q77A9ix'
};

class Webhook {
	#url;
	#username;
	#password;
	#token;
	#userID;
	#cancelSource;
	#prompt;
	#authorName;

	constructor(url, username, password, prompt, token) {
		this.#url = url;
		this.#username = username;
		this.#password = password;
		this.#cancelSource = axios.CancelToken.source();
		this.#prompt = prompt;
		this.#authorName = userDataController().getData('authorName');
		this.#token = token;
	}

	getUserID() {
		this.#userID = Session.getUserID();
	}

	async request(method, endpoint, data = {}, params = {}, token, url) {
		this.getUserID();

		try {
			const response = await axios({
				method,
				url: `${url || this.#url}/${endpoint}`,
				params,

				headers: {
					Authorization: token && `Bearer ${token}`,
					'Content-Type': data?.photoPost ? 'multipart/form-data' : 'application/json'
				},
				// data: { ...data, userID: !token ? this.#userID : undefined },
				data: { ...data, userID: this.#userID },
				auth: !token && { username: this.#username, password: this.#password },

				cancelToken: this.#cancelSource.token
			});
			return response.data;
		} catch (error) {
			if (axios.isCancel(error)) {
				return Promise.reject('Запрос отменен');
			}
			return Promise.reject(error?.response?.data?.message);
		}
	}

	get(endpoint, id) {
		this.getUserID();
		const params = id ? { id } : { userID: this.#userID };
		return this.request('get', endpoint, null, params);
	}

	post(endpoint, ssID, data) {
		return this.request('post', endpoint, { ssID, ...data });
	}

	remove(endpoint, chenID) {
		return this.request('delete', endpoint, { telegram_id_chen: chenID });
	}

	cancelAllRequests() {
		this.#cancelSource.cancel('Все запросы отменены');
		this.#cancelSource = axios.CancelToken.source();
	}

	create(endpoint, data) {
		const { telegram_id_chen, ...fields } = data || {};
		return this.request('post', endpoint, fields);
	}

	edit(endpoint, data) {
		// if (!data?.telegram_id_chen && !data.id) return Promise.reject('Выберите соцсети');
		const { telegram_id_chen, ...fields } = data || {};
		return this.request('post', endpoint, fields);
	}

	publish(endpoint, data) {
		if (!data?.telegram_id_chen) return Promise.reject('Выберите соцсети');
		const { id, telegram_id_chen, pubDate } = data || {};
		return this.request('post', endpoint, { id, telegram_id_chen, pubDate });
	}

	delete(endpoint, id) {
		return this.request('delete', endpoint, { id });
	}

	chatAI(endpoint, text) {
		const payload = {
			user: this.#authorName,
			id: Session.getUserID(),
			text: `${this.#prompt} ${text}`
		};
		return this.request(
			'post',
			endpoint,
			payload,
			null,
			this.#token,
			'https://pushkinbot.store/webhook'
		);
	}
}

export const webhook = new Webhook(...Object.values(config));

import showdown from 'showdown';
import axios from 'axios';

const DataUtils = (function () {
	const _markdownToHtml = (markdown) => {
		const converter = new showdown.Converter({
			strikethrough: true,
			fencedCodeBlocks: true,
			tables: true,
			emoji: true
		});
		const html = converter.makeHtml(markdown);
		return html;
	};

	const _copyHtmlToClipboard = (result) => {
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = result;

		const item = new ClipboardItem({
			'text/html': new Blob([tempDiv.innerHTML], { type: 'text/html' })
		});

		navigator.clipboard.write([item]);
	};

	const _copyTextToClipboard = (result) => {
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = result;
		const resultText = tempDiv.innerText;
		return resultText;
	};

	const _getCurrentDateFormatted = () => {
		const now = new Date();

		const day = String(now.getDate()).padStart(2, '0');
		const monthNames = [
			'января',
			'февраля',
			'марта',
			'апреля',
			'мая',
			'июня',
			'июля',
			'августа',
			'сентября',
			'октября',
			'ноября',
			'декабря'
		];
		const month = monthNames[now.getMonth()];
		const year = now.getFullYear();

		return `${day} ${month} ${year}`;
	};

	const _isUrl = (string) => {
		const urlPattern = new RegExp(
			'^(https?:\\/\\/)?' +
				'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
				'((\\d{1,3}\\.){3}\\d{1,3}))' +
				'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
				'(\\?[;&a-z\\d%_.~+=-]*)?' +
				'(\\#[-a-z\\d_]*)?$',
			'i'
		);
		return !!urlPattern.test(string);
	};

	const _cleanHtmlText = (text) => {
		const div = document.createElement('div');
		div.innerHTML = text;
		return div.textContent || div.innerText;
	};

	const _fetchImageMetadataAndBase64 = async (imageUrl, origin) => {
		if (!imageUrl || typeof imageUrl !== 'string' || !imageUrl.startsWith('http')) {
			return;
		}

		try {
			const response = await axios.get(imageUrl, {
				responseType: 'arraybuffer'
			});

			const arrayBuffer = response.data;
			const base64String = btoa(
				new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
			);

			const thumbUrl = `data:image/jpeg;base64,${base64String}`;

			const originFileObj = {
				uid: `rc-upload-${Date.now()}`,
				name: `${Date.now()}.jpg`,
				lastModified: Date.now(),
				lastModifiedDate: new Date(),
				webkitRelativePath: '',
				size: arrayBuffer.byteLength,
				url: imageUrl
			};

			const file = new File([arrayBuffer], `${Date.now()}.jpg`, {
				type: 'image/jpeg',
				lastModified: Date.now(),
				lastModifiedDate: new Date(),
				url: imageUrl
			});

			if (origin) {
				return file;
			}

			return {
				lastModified: Date.now(),
				lastModifiedDate: new Date(),
				name: `${Date.now()}.jpg`,
				originFileObj: file,
				percent: 100,
				size: arrayBuffer.byteLength,
				thumbUrl,
				url: imageUrl,
				type: 'image/jpeg',
				uid: `rc-upload-${Date.now()}`
			};
		} catch (error) {
			console.error(`Ошибка скачивания изображения: ${error.message}`, error);
			throw new Error(`Ошибка скачивания изображения: ${error.message}`);
		}
	};

	return {
		markdownToHtml: _markdownToHtml,
		copyHtmlToClipboard: _copyHtmlToClipboard,
		copyTextToClipboard: _copyTextToClipboard,
		getCurrentDateFormatted: _getCurrentDateFormatted,
		isUrl: _isUrl,
		cleanHtmlText: _cleanHtmlText,
		fetchImageMetadataAndBase64: _fetchImageMetadataAndBase64
	};
})();

export default DataUtils;

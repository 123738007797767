import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { IconButton } from '@mui/material';
import _ from 'underscore';

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right'
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color: 'rgb(55, 65, 81)',
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0'
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5)
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
			}
		},
		...theme.applyStyles('dark', {
			color: theme.palette.grey[300]
		})
	}
}));

export const DropdownButton = ({ icon, data, label }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (e) => {
		e.preventDefault();

		setAnchorEl(e.currentTarget);
	};
	const handleClickItem = (e, link) => {
		e.stopPropagation();

		if (link) {
			_.isFunction(link) ? link() : window.open(link);
		}
		setAnchorEl(null);
	};

	return (
		<div onClick={(e) => e.stopPropagation()}>
			{label ? (
				<MenuItem onClick={handleClick}>
					{icon}
					{label}
				</MenuItem>
			) : (
				<IconButton size="large" color="inherit" onClick={handleClick}>
					{icon}
				</IconButton>
			)}

			<StyledMenu anchorEl={anchorEl} open={open} onClose={(e) => handleClickItem(e, null)}>
				{data.map(
					(item) =>
						!item?.show && (
							<MenuItem
								disabled={item?.disable}
								key={item?.label}
								onClick={(e) => handleClickItem(e, item?.link)}
							>
								{item?.icon}
								{item?.label}
							</MenuItem>
						)
				)}
			</StyledMenu>
		</div>
	);
};

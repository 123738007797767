import _ from 'underscore';
import { ArticleCategory } from './Components/ArticleCategory';
import { NewsCellPublicationDate } from './Components/NewsCellPublicationDate';
import { Box, IconButton, MenuItem, Typography, styled } from '@mui/material';
import { EyeOutlined } from './EyeOutlined';
import { Skeleton } from '@mui/material';
import { userDataController } from '../../controllers/userDataController';
import { DropdownButton } from '../../Components/DropdownButton/DropdownButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useNotification } from '../../hooks/useNotification';

import PersonIcon from '@mui/icons-material/Person';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import Net from '../../Libs/Net';
import { webhook } from '../SocialPage/webhook';
import dayjs from 'dayjs';

const ArticleItemStyle = styled(Box)`
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	overflow: hidden;
	position: relative;
	background-position: center center;
	aspect-ratio: 1/1;
	border-radius: 25px;
	background-color: white;
	padding-top: 40px;
`;

const ContentBox = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-top: auto;
	color: white;
	width: 100%;
	position: relative;
	z-index: 0;
	padding: 0 20px 15px 20px;

	::before {
		content: '';
		position: absolute;
		filter: blur(15px);
		width: 100%;
		height: 100%;
		height: '100%';
		background: rgba(26, 25, 25, 0.6);
		bottom: 0;
		z-index: -1;
		margin-left: -20px;
	}
`;

const TypographyText = styled(Typography)`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.15;
`;

const TypographyTitle = styled(Typography)`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.15;

	&.MuiTypography-h2 {
		font-size: 1rem !important;
	}
`;

const MetaDataBox = styled(Box)`
	gap: 0.5rem;
`;

const SkeletonBox = styled(Box)`
	background: white;
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	position: relative;
	aspect-ratio: 1/1;
`;

const SkeletonStyle = styled(Skeleton)`
	position: absolute;
	top: 0;
	transform: scale(1);
	height: 100%;
	border-radius: 25px;
`;

const HeadButtonBox = styled(Box)`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: space-between;
	top: 0;
	/* right: 0; */
	width: 100%;

	svg {
		/* background: rgba(0, 0, 0, 0.2); */
		border-radius: 50%;
	}

	::before {
		content: '';
		position: absolute;
		filter: blur(20px);
		width: 100%;
		height: 100%;
		background: rgba(26, 25, 25, 0.6);
		right: 0;
		top: 0px;
		z-index: 0;
		margin-left: -20px;
	}
`;

const HourglassEmptyOutlinedIconStyle = styled(HourglassEmptyOutlinedIcon)`
	color: white;
	width: 80px;
	height: 80px;
	z-index: 1;
`;

export const ArticleItem = function ({ article, loading, setItems, planItem }) {
	const getUserData = userDataController().getData.bind(userDataController());

	const { success, error } = useNotification();

	const getImageUrl = () => {
		if (article?.articleType === 3) {
			return getUserData('avatarID')?.Image2;
		}

		if (_.has(article.image, 'Image2')) {
			return article.image['Image2'];
		}
		return article.image?.original;
	};

	const goToExternalLink = (e) => {
		e.preventDefault();
		window.open(`https://www.gosrf.ru/${article['titleIdx']}`);
	};

	if (loading) {
		return (
			<SkeletonBox>
				<SkeletonStyle animation="wave" variant="text" width="100%" />
				<Box sx={{ p: '1rem', mt: 'auto' }}>
					<Box sx={{ display: 'flex', gap: '1rem' }}>
						<Skeleton variant="text" width="50%" />
						<Skeleton variant="text" width="50%" />
					</Box>
					<Skeleton variant="text" width="60%" sx={{ m: '.5rem 0' }} />
					<Skeleton variant="text" width="80%" />
					<Skeleton variant="text" width="70%" />
					<Skeleton variant="text" width="60%" />
				</Box>
			</SkeletonBox>
		);
	}

	const handleDelete = (id) => {
		if (!article?.id) return error('Ошибка удаления');

		if (id.split('-')[0] === 'act') {
			if (planItem) {
				return setItems((prev) => prev.filter((item) => item.id !== article.id));
			}

			webhook
				.delete('ssPostDelete', id)
				.then(() => {
					success('Материал удален');
					setItems((prev) => prev.filter((item) => item.id !== article.id));
				})
				.catch(() => {
					error('Ошибка удаления');
				});
		} else {
			Net.post('deleteArticle', { id })
				.then(() => {
					success('Материал удален');
					setItems((prev) => prev.filter((item) => item.id !== article.id));
				})
				.catch(() => {
					error('Ошибка удаления');
				});
		}
	};

	const handleCancel = (id) => {
		console.log('В разработке');
	};

	const dropdownData = [
		{
			label: 'Удалить публикацию',
			icon: <DeleteOutlineOutlinedIcon />,
			link: () => handleDelete(article?.id)
		},
		{
			label: 'Снять с публикации',
			icon: <CancelOutlinedIcon />,
			link: () => handleCancel(article?.id),
			// 	disable: true
			show: article.articleType === 3 || !article.titleIdx
		},
		{
			label: 'Посмотреть',
			icon: <RemoveRedEyeOutlinedIcon />,
			link: `https://www.gosrf.ru/${article['titleIdx']}`,
			show: !article.titleIdx
		}
	];

	const variantIcons = {
		1: <TextSnippetOutlinedIcon />,
		3: <PersonIcon />,
		4: <PhoneAndroidOutlinedIcon />
	};

	return (
		<ArticleItemStyle
			sx={{
				backgroundImage: `url('${getImageUrl()}')`
			}}
		>
			{/* <EyeOutlined onClick={goToExternalLink} titleIdx={article['titleIdx']} /> */}
			<HeadButtonBox color="white">
				<IconButton
					sx={{
						color: 'white',
						aspectRatio: '1/1',
						width: '48px'
					}}
				>
					{variantIcons[article.articleType]}
				</IconButton>
				<DropdownButton icon={<MoreVertIcon />} data={dropdownData} />
			</HeadButtonBox>

			{dayjs(article['pubDate']).isAfter(dayjs()) && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						position: 'absolute',
						top: '50%',
						transform: 'translate(0, -50%)',
						'::before': {
							content: '""',
							position: 'absolute',
							filter: 'blur(35px)',
							background: 'rgba(26, 25, 25, 0.6)',
							// background: 'red',
							width: '100%',
							height: '100px',
							zIndex: '0'
						},
						width: '100%'
					}}
				>
					<HourglassEmptyOutlinedIconStyle />
				</Box>
			)}

			<ContentBox>
				<MetaDataBox>
					<ArticleCategory style={{ width: '100%' }} category={article['categoryName']} />
					<NewsCellPublicationDate
						style={{ width: '100%', justifyContent: 'end' }}
						pubDate={article['pubDate']}
					/>
				</MetaDataBox>
				<TypographyTitle variant="h2">{article['title']}</TypographyTitle>
				<TypographyText>{article['preText']}</TypographyText>
			</ContentBox>
		</ArticleItemStyle>
	);
};

import { styled } from '@mui/material';
import React from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const EyeOutlinedStyle = styled(RemoveRedEyeOutlinedIcon)`
	border-radius: 25px 0;
	background: rgba(0, 0, 0, 0.3);
	color: white;
	padding: 0.4rem;
	width: 3rem;
	height: 2rem;
	position: absolute;
	top: 0;
	&:hover {
		color: rgba(255, 255, 255, 0.5);
	}
`;

export const EyeOutlined = ({ titleIdx, onClick }) =>
	titleIdx ? <EyeOutlinedStyle onClick={onClick} /> : null;

import { Spinner } from '../../ArticlesPage/Components/Spinner';
import { ReactComponent as Lightbulb } from '../../../Assets/Components/pushkin_assets/lightbulb.svg';
import { Progress, Typography } from 'antd';
import pushkinImg from '../../../Assets/Components/pushkin_assets/pushkin_img.svg';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const { Text } = Typography;

const PreloaderStyle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
	.pushkin-img {
		width: 330px;
		height: 330px;
		background: url('${(props) => props.$bg}');
		background-repeat: no-repeat;
		background-size: contain;
		margin-top: 30px;
	}

	.progress-bar {
		margin: 0;
		width: 90%;
	}
`;

export const ChatGPTPreloader = ({ response, callback }) => {
	const [stage, setStage] = useState(0);

	useEffect(() => {
		const loadingTime = response ? 200 : Math.floor(30 / stages.length - 1) * 1000;

		const timer = setTimeout(nextStage, stage === stages.length - 1 ? 1000 : loadingTime);
		return () => clearTimeout(timer);
	}, [stage, response]);

	const stages = [
		'Внимательно читаю задание',
		'Заглядываю в мою базу знаний',
		'Начинаю писать текст',
		'Ещё немного и всё будет готово',
		'Остались последние штрихи',
		'Всё готово!'
	];

	const nextStage = () => {
		if (stage === stages.length - 2 && !response) {
			return;
		}

		if (stage < stages.length - 1) {
			setStage(stage + 1);
		} else {
			callback();
		}
	};

	const gradient = {
		'0%': '#f8a1f5',
		'100%': '#df01e2'
	};

	const setStyle = () => ({ marginLeft: '60px' });

	return (
		<PreloaderStyle $bg={pushkinImg}>
			<div className="pushkin-img">
				{stage === stages.length - 1 ? (
					<Lightbulb style={setStyle()} />
				) : (
					<Spinner style={setStyle()} />
				)}
			</div>
			<Progress
				className="progress-bar"
				percent={stage * 20}
				strokeLinecap="butt"
				trailColor="#dcd1fa"
				strokeColor={gradient}
				size={[null, 15]}
				showInfo={false}
			/>
			<Text strong>{stages[stage]}</Text>
		</PreloaderStyle>
	);
};

import { useState } from 'react';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DataUtils from '../../../../Libs/DataUtils';

export const CopyButton = ({ result }) => {
	const [isDone, setIsDone] = useState(false);
	const theme = useTheme();
	const blueColor = theme.palette.systemBlue.main;

	const text = DataUtils.copyTextToClipboard(result);

	const handleCopy = async () => {
		setIsDone(true);
		try {
			await navigator.clipboard.writeText(text);
		} catch (err) {}
		setTimeout(() => setIsDone(false), 3000);
	};

	const renderIcon = (
		<>
			{isDone && <DoneOutlinedIcon sx={{ color: blueColor }} />}
			{!isDone && <ContentCopyOutlinedIcon sx={{ fontSize: '1.2rem', color: blueColor }} />}
		</>
	);

	return (
		<Tooltip title="Копировать">
			<IconButton variant="contained" color="primary" onClick={handleCopy}>
				{renderIcon}
			</IconButton>
		</Tooltip>
	);
};

import { Modal, LinearProgress, Paper, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotification } from '../../../hooks/useNotification';
import { webhook } from '../webhook';
import { channelsStructure } from './components/constants';
import { ModalHeader } from './components/ModalHeader';
import { StepContent } from './components/StepContent';

const ModalStyle = styled(Paper)(({ theme }) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	background: 'white',
	borderRadius: 35,
	padding: '0 2rem 3rem 2rem',
	display: 'flex',
	width: '70%',
	maxWidth: 700,
	flexDirection: 'column',
	alignItems: 'center',
	gap: '3rem',
	overflow: 'hidden',
	'.text-block': {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		alignItems: 'center',
		gap: '1rem',
		marginTop: '-3rem'
	},
	[theme.breakpoints.down('sm')]: {
		width: '90%'
	},
	[theme.breakpoints.down('xs')]: {
		width: '100%'
	}
}));

export const SocialModal = ({ open, setOpen, update, newStep }) => {
	const [inputText, setInputText] = useState(channelsStructure);
	const [loading, setLoading] = useState(false);
	const [step, setStep] = useState(1);
	const { success, error } = useNotification();

	useEffect(() => {
		setStep(newStep ? newStep : 1);
	}, [newStep, open]);

	const resetForm = () => {
		setInputText(channelsStructure);
		setStep(1);
		setOpen(false);
	};

	const handleClose = () => {
		webhook.cancelAllRequests();
		resetForm();
	};

	const sendMessage = async () => {
		if (!inputText) return error('Поле текста не может быть пустым');

		setLoading(true);
		try {
			await webhook.post('sscreate', 'tg', inputText);
			success('Канал успешно добавлен');
			resetForm();
			update();
		} catch (err) {
			error(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={handleClose}>
			<ModalStyle>
				{loading && (
					<LinearProgress sx={{ height: '5px', width: '100%', position: 'absolute' }} />
				)}
				<ModalHeader handleClose={handleClose} />
				<StepContent
					step={step}
					setStep={setStep}
					inputText={inputText}
					setInputText={setInputText}
					sendMessage={sendMessage}
					loading={loading}
				/>
			</ModalStyle>
		</Modal>
	);
};

import { Button } from '@mui/material';

import { ReactComponent as OkIcon } from '../../../Assets/Logo/ok_icon.svg';
import { useEffect, useState } from 'react';
import { webhook } from '../../SocialPage/webhook';

export const OkAuth = () => {
	const [codeVerifier, setCodeVerifier] = useState(null);

	useEffect(() => {
		webhook
			.get('vk_oauth')
			.then((res) => {
				setCodeVerifier(res);
			})
			.catch((err) => console.error(err));
	}, []);

	const config = {
		clientId: '512002748419',
		redirectUri: 'https://govorun.pushkinbot.ru/oauht-ok/',
		scope: 'VALUABLE_ACCESS;PHOTO_CONTENT;GROUP_CONTENT;VIDEO_CONTENT;',
		responseType: 'code',
		state: codeVerifier?.state,
		layout: 'w',
		configParam: ''
	};

	const handleAuthorization = async () => {
		const url = `https://connect.ok.ru/oauth/authorize?client_id=${config.clientId}&scope=${config.scope}&response_type=${config.responseType}&redirect_uri=${config.redirectUri}&layout=${config.layout}&state=${config.state}`;

		// window.location.href = url;
		window.open(url);
	};

	return (
		<Button
			variant="contained"
			fullWidth
			onClick={handleAuthorization}
			startIcon={<OkIcon width={24} fill="#fff" />}
			sx={{
				display: 'flex',
				alignItems: 'center',
				background: '#ff7700',
				':hover': {
					background: '#eb7d01'
				}
			}}
		>
			<span>Войти в Одноклассники</span>
		</Button>
	);
};

import { Button } from '@mui/material';

export const AuthButton = ({ children, controller, onClick }) => (
	<Button
		sx={{
			borderRadius: '100px',
			color: 'white',
			textTransform: 'none',
			marginTop: '1rem',
			fontSize: '20px',
			padding: '.5rem 2rem'
		}}
		loading={controller.loading.toString()}
		onClick={onClick}
		color="systemRed"
		size="large"
		variant="contained"
	>
		{children}
	</Button>
);

import { Box, Typography } from '@mui/material';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { OkAuth } from '../../../../SimpleArticleEdit/Components/OkAuth';

export const Ok = () => (
	<Box className="text-block">
		<Typography
			variant="h5"
			fontWeight={700}
			sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
		>
			<LibraryAddOutlinedIcon /> Добавление OK
		</Typography>

		<OkAuth />
	</Box>
);

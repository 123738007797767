import Net from '../Libs/Net';
import Config from './Config';

const Session = (function () {
	let userID = null;
	let authorID = null;
	let isAuthor = null;
	let avatar = null;
	let token = null;
	let postName = null;
	let level = null;
	let isIntern = false;
	let publicDelay = 0;
	let categories = [];
	let workDay = null;
	let remainQty = null;
	let tariffID = null;
	let tariffName = null;

	const loadSession = () => {
		return new Promise((resolve, reject) => {
			if (!Config.isAuth()) {
				resolve();
				return;
			}
			Net.post('getSession', {})
				.then((res) => {
					postName = res.postName;
					userID = res.userID;
					authorID = res.authorID;
					isAuthor = res.isAuthor;
					avatar = res.avatar;
					token = res.token;
					level = res.level;
					isIntern = res.isIntern;
					publicDelay = res.publicDelay;
					categories = res.categories;
					workDay = res.workDay;
					remainQty = res.remainQty;
					tariffID = res.tariffID;
					tariffName = res.tariffName;

					resolve();
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	return {
		init: () => {
			return loadSession();
		},
		getUserID: () => {
			return userID;
		},
		getAuthorID: () => {
			return authorID;
		},
		getIsAuthor: () => {
			return isAuthor;
		},
		getAvatar: () => {
			return avatar;
		},
		getToken: () => {
			return token;
		},
		getPostName: () => {
			return postName;
		},
		getLevel: () => {
			return level;
		},
		isIntern: () => {
			return isIntern;
		},
		getPublicDelay: () => {
			return publicDelay;
		},
		getCategories: () => {
			return categories;
		},
		getWorkDay: () => {
			return workDay;
		},
		getRemainQty: () => {
			return remainQty;
		},
		getTariffID: () => {
			return tariffID;
		},
		getTariffName: () => {
			return tariffName;
		}
	};
})();

export default Session;

import { Step1 } from '../components/steps/Step1';
import { Step2 } from '../components/steps/Step2';
import { Step3 } from '../components/steps/Step3';
import { Ok } from './steps/Ok';
import { Vk } from './steps/Vk';

export const StepContent = ({ step, setStep, inputText, setInputText, sendMessage, loading }) => {
	const steps = {
		1: <Step1 setStep={setStep} />,
		2: <Step2 setStep={setStep} inputText={inputText} setInputText={setInputText} />,
		3: (
			<Step3
				sendMessage={sendMessage}
				loading={loading}
				inputText={inputText}
				setInputText={setInputText}
			/>
		),
		4: <Vk setStep={setStep} inputText={inputText} setInputText={setInputText} />,
		5: <Ok setStep={setStep} inputText={inputText} setInputText={setInputText} />
	};

	return steps[step];
};

import { useEffect } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';

export const TextInputAI = ({
	controller,
	type = 'text',
	value,
	setValue,
	icon,
	label,

	defaultValue
}) => {
	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	return (
		<TextField
			sx={{ width: '300px' }}
			placeholder="Адрес сайта"
			type={type}
			fullWidth
			label={label}
			variant="outlined"
			value={value}
			onChange={(e) => setValue(e.target.value)}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<LanguageOutlinedIcon />
					</InputAdornment>
				)
			}}
		/>
	);
};

import Repository from '../Libs/Repository';
import Utils from '../Libs/Utils';
import Iveno from '../Libs/Iveno/Iveno';
import Session from './Session';

export const RepositoryKeys = {
	token: 'RR_PR_TOKEN'
};

export const SystemEvents = {
	onConfigRefreshed: '061ea9ae-abe5-497a-b139-5487220ae50d'
};

const Config = (function () {
	let _app;

	const _updateUI = () => {
		Iveno.Bus.emit(SystemEvents.onConfigRefreshed);
	};

	return {
		setApp: (app) => {
			_app = app;
		},
		getApp: () => {
			return _app;
		},
		setToken: (token) => {
			if (Utils.isEmpty(token)) {
				Repository.removeStore(RepositoryKeys.token);
				_updateUI();
			} else {
				Session.init()
					.then(() => {
						Repository.store(RepositoryKeys.token, token);
						window.location.reload();
					})
					.catch(() => {});
			}
		},
		isAuth: () => {
			return !Utils.isEmpty(Repository.restore(RepositoryKeys.token));
		},
		getToken: () => {
			return Repository.restore(RepositoryKeys.token);
		}
	};
})();

export default Config;

import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';

import styled from 'styled-components';

const UploadStyle = styled(Upload)`
	.ant-upload {
		button {
			cursor: pointer;
		}
	}
`;

export const UploadFiles = ({ files, setFiles }) => {
	const [messageApi, contextHolder] = message.useMessage();

	const numberFiles = 1;

	const handleChange = ({ fileList }) => {
		let fileClass;

		const filteredFiles = fileList.filter((file) => {
			const fileExtensions = [
				[
					'file_format',
					'.c',
					'.cs',
					'.cpp',
					'.docx',
					'.html',
					'.java',
					'.json',
					'.md',
					'.pdf',
					'.php',
					'.pptx',
					'.py',
					'.py',
					'.rb',
					'.tex',
					'.txt',
					'.css',
					'.js',
					'.sh',
					'.ts'
				],

				['audio_format', '.mp3', '.mp4', '.mpeg', '.mpga', '.m4a', '.wav', '.webm'],
				['photo_format', '.jpg', '.jpeg', '.png', '.gif']
			];

			const getFileType = (file) => {
				return `.${file.originFileObj.name.split('.').pop()}`;
			};

			fileClass = fileExtensions.find((el) => el.includes(getFileType(file)))?.[0];

			// if (!fileExtensions.forEach((el) => el.includes(getFileType(file)))) {
			if (!fileClass) {
				messageApi.open({
					type: 'error',
					content: 'Ассистент пока не умеет читать такие файлы',
					className: 'custom-ant-message',
					duration: 4
				});
				return false;
			}

			file['fileClass'] = fileClass;

			return true;
		});

		setFiles(filteredFiles);
	};
	const uploadButton = (
		<button
			style={{
				border: 0,
				background: 'none'
			}}
			type="button"
		>
			<PlusOutlined />
			<div
				style={{
					marginTop: 8
				}}
			>
				Загрузить
			</div>
		</button>
	);
	return (
		<>
			{contextHolder}
			<UploadStyle
				listType="picture-card"
				beforeUpload={() => false}
				fileList={files}
				onChange={handleChange}
				showUploadList={{ showPreviewIcon: false }}
				accept="*"
			>
				{files.length >= numberFiles ? null : uploadButton}
			</UploadStyle>
		</>
	);
};

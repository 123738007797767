import { Box, Button, Typography, Link } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import { TextInputField } from '../TextInputField';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { useNotification } from '../../../../../hooks/useNotification';

export const Step3 = ({ sendMessage, loading, inputText, setInputText }) => {
	const { error } = useNotification();

	const handleConnect = () => {
		if (!inputText.code.trim()) {
			return error('Поле не может быть пустым');
		}
		sendMessage();
	};

	return (
		<Box className="text-block">
			<Typography
				variant="h5"
				fontWeight={700}
				sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
			>
				<LibraryAddOutlinedIcon /> Добавление Telegram
			</Typography>
			<Typography variant="body1" textAlign="center" fontWeight={700}>
				3 шаг
				<br />
				Авторизуйтесь в нашем боте
			</Typography>
			<Typography variant="body2">
				<ol>
					<li>
						Найдите в поиске Telegram нашего специального бота “Репостер-РР Пиар” @rr_pr_bot
						или пройдите по{' '}
						<Link href="https://t.me/rr_pr_bot?start=start" target="_blank">
							ссылке
						</Link>
					</li>
					<li>
						Нажмите кнопку <strong>Старт (Start)</strong> или напишите боту команду{' '}
						<strong>/start</strong>
					</li>
					<li>Скопируйте полученный код в поле:</li>
				</ol>
			</Typography>
			<TextInputField
				placeholder="Вставьте код от бота"
				value={inputText.code}
				onChange={(e) => setInputText({ ...inputText, code: e.target.value })}
				icon={<TelegramIcon sx={{ color: 'systemBlue.main' }} />}
				field="code"
			/>
			<Button disabled={loading} onClick={handleConnect} variant="contained">
				Подключить
			</Button>
		</Box>
	);
};

import { useEffect, useRef, useState } from 'react';
import Iveno from '../../../Libs/Iveno/Iveno';
import axios from 'axios';
import _ from 'underscore';
import DataUtils from '../../../Libs/DataUtils';
import { ChatGPTPreloader } from './ChatGPTPreloader';
import { UploadFiles } from './UploadFiles';

import { articlesRegController } from '../../../controllers/articlesRegController';
import {
	Box,
	DialogTitle,
	Drawer as DrawerMUI,
	IconButton,
	styled,
	Button as ButtonMUI,
	TextField,
	useMediaQuery,
	Typography,
	Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useTheme } from '@emotion/react';
import { CopyButton } from './Components/CopyButton';
import { AddToEditButton } from './Components/AddToEditButton';
import { useNotification } from '../../../hooks/useNotification';
import Session from '../../../Config/Session';
import { userDataController } from '../../../controllers/userDataController';
import { AddToEditImgButton } from './Components/AddToEditImgButton';
import { EmailInputAI } from './Components/EmailInputAI';
import { TextInputAI } from './Components/TextInputAI';
import { webhook } from '../../SocialPage/webhook';
import { ArticlesList } from '../../ArticlesPage/ArticlesPage';
import { Link } from 'react-router-dom';
import { ArticleItem } from '../../ArticlesPage/ArticleItem';

const ChatGPTDrawerStyle = styled('div')`
	.resultWrapper {
		margin-top: 20px;
		border-top: 1px solid var(--color-gray);
		padding-top: 20px;
		padding-bottom: 20px;

		h1 {
			font-size: 1.8rem;
		}

		h2 {
			font-size: 1.4rem;
		}

		h3 {
			font-size: 1.1rem;
		}
	}

	.buttons {
		display: flex;
		justify-content: space-between;
	}
`;

const ClearButtonStyle = styled(ButtonMUI)`
	border-radius: 100px;
`;

const TextFieldStyle = styled(TextField)`
	width: 100%;
	.MuiInputBase-root .Mui-disabled {
		background: rgba(239, 239, 239, 0.3);
	}
`;

const eventID = '14721b15-d483-4c96-a46b-2bb52b90db7e';

export const openChatGPTDrawer = (payload) => {
	Iveno.Bus.emit(eventID, payload);
};

export const ChatGPTDrawer = function ({ callback }) {
	const controller = articlesRegController();
	const [open, setOpen] = useState(false);
	const [isClosedUI, setIsClosedUI] = useState(true);
	const [loading, setLoading] = useState(false);
	const [loadResponse, setLoadResponse] = useState(false);
	const [str, setStr] = useState('');
	const [inputs, setInputs] = useState('');
	const [siteText, setSiteText] = useState('');
	const [chips, setChips] = useState([
		{ label: 'напиши', text: 'напиши', active: false },
		{ label: 'найди', text: 'найди', active: false },
		{ label: 'нарисуй', text: 'нарисуй', active: false },
		{ label: 'сканируй', text: '', active: false },
		{ label: 'расшифруй', text: '', active: false },
		{ label: 'спланируй', text: '', active: false, select: true }
	]);
	const [result, setResult] = useState(null);
	const [resultPlan, setResultPlan] = useState(null);
	const [isImage, setIsImage] = useState(false);
	const [isImgButtons, setIsImgButtons] = useState(true);
	const [files, setFiles] = useState([]);
	const [planning, setPlanning] = useState(false);
	const [email, setEmail] = useState('');
	const [previousWidth, setPreviousWidth] = useState(window.innerWidth);
	const theme = useTheme();
	const isMd = useMediaQuery(theme.breakpoints.down('md'));
	const { success, error } = useNotification();

	const contextualize = () => {
		const chip = chips.find((chip) => chip.active)?.text || '';

		if (!files.length && !inputs) {
			const bodyText = controller.getData('bodyText');

			if (bodyText && (chip === 'напиши' || chip === 'найди')) {
				const tmp = document.createElement('div');
				tmp.innerHTML = bodyText;

				return `${tmp.textContent} \n\n ${str} \n ${inputs || ''}`;
			}
		}

		return `${chip} ${str} \n ${inputs || ''}`;
	};

	useEffect(() => {
		const scopeID = Iveno.Crypt.random();
		Iveno.Bus.on(
			eventID,
			(payload) => {
				setOpen(true);
				setIsClosedUI(!isClosedUI);
			},
			scopeID
		);
		return () => {
			Iveno.Bus.offScope(scopeID);
		};
	}, []);

	useEffect(() => {
		!str && setInputs('');
	}, [str]);

	const onCancel = () => {
		setOpen(false);
		setIsClosedUI(!isClosedUI);
	};

	useEffect(() => {
		const handleResize = () => {
			const currentWidth = window.innerWidth;
			if (currentWidth !== previousWidth) {
				if (currentWidth <= 768) {
					setOpen(false);
				}
				setPreviousWidth(currentWidth);
			}
		};

		if (isMd) {
			window.addEventListener('resize', handleResize);
		}

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isMd, previousWidth]);

	const controllerRef = useRef(null);

	async function makeWebhookRequest(reset, num) {
		const url = 'https://pushkinbot.store/webhook/rr_pr';
		const token = 'nKD5pgshc30G3Q77A9ix';

		const file = files[0];

		if (!reset) {
			setLoading(true);
			setResult(null);
			setLoadResponse(true);
			setIsImgButtons(true);
		}

		const payload = {
			user: userDataController().getData('authorName'),
			id: Session.getUserID(),
			text: contextualize()
		};

		if (reset) {
			delete payload.text;
			payload['delete_thread'] = true;
			setFiles([]);
		}

		if (num) {
			delete payload.text;
			payload['photo_key'] = num;
			setFiles([]);
			setIsImgButtons(false);
		}

		if (file?.fileClass) {
			const fileSplitName = file?.name.split('.');

			const fileFormat = `.${fileSplitName[fileSplitName.length - 1]}`;

			payload[file.fileClass] = fileFormat;
			payload['data'] = file.originFileObj;
			if (file?.fileClass === 'audio_format') {
				delete payload.text;
			}
		}

		try {
			const response = await axios.post(url, payload, {
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': file?.originFileObj ? 'multipart/form-data' : 'application/json'
				},

				signal: controllerRef.current.signal,
				timeout: 600000
			});

			if (response.status === 200) {
				setIsImage(false);
				if (response.data?.responseUrl) {
					setResult(response.data.responseUrl);
					setLoading(false);
					setIsImage(true);
					setFiles([]);
					if (isClosedUI) {
						setLoadResponse(false);
					}
					return;
				}

				if (response.data?.delete_thread) {
					success('Диалог обновлен');
					setResult('');
					setIsImage(false);
					setFiles([]);
				}

				const html = DataUtils.markdownToHtml(response.data.response);
				setResult(html);
				setLoading(false);
				setFiles([]);
				if (isClosedUI) {
					setLoadResponse(false);
				}
			} else {
				throw new Error(response.data.response);
			}
		} catch (error) {
			if (error.name !== 'AbortError') {
				setLoading(false);
				setLoadResponse(false);
			}
		}
	}

	const sendMessage = () => {
		setLoading(true);
		setResult(null);
		setLoadResponse(true);
		controllerRef.current = new AbortController();

		let content = str + '\n' + inputs;

		if (content.length > 10000) {
			error('Слишком много текста. Максимальная длина - 10000 символов.');
			setLoading(false);
			setLoadResponse(false);
			return;
		}

		if (files[0]?.fileClass === 'audio_format') {
			const maxSizeInBytes = 100 * 1024 * 1024;
			if (files[0].size > maxSizeInBytes) {
				error('Размер файла превышает 100 МБ');
				setLoading(false);
				setLoadResponse(false);
				return;
			}
		}
		if (planning) {
			setResultPlan(null);
			webhook
				.post('planning', null, {
					text: contextualize(),
					site: siteText
				})
				.then((res) => {
					setResult(null);
					setResultPlan(res);
					setLoading(false);
					setIsImage(true);
					setFiles([]);
					setLoadResponse(false);
				})
				.catch((err) => {
					setLoading(false);
					setLoadResponse(false);
				});
		} else {
			makeWebhookRequest();
		}
	};

	const clearInputs = () => {
		setInputs('');
		setStr('');
		setResult(null);
		setFiles([]);
		setEmail('');
		setSiteText('');
		setPlanning(null);
		!planning && setChips(chips.map((chip) => ({ ...chip, active: false })));
	};

	const cancelRequest = () => {
		if (planning) {
			return webhook.cancelAllRequests();
		}
		if (controllerRef.current) {
			controllerRef.current.abort();
		}
	};

	const textStyle = {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontWeight: 400,
		letterSpacing: '0.08333em'
	};

	const deleteThread = () => {
		clearInputs();
		makeWebhookRequest(true);
	};

	const handleClickChip = (chipLabel, isPlanning, active) => {
		setChips(
			chips.map((chip) => ({ ...chip, active: chip.label === chipLabel && !chip.active }))
		);

		if (isPlanning && !active) {
			setPlanning(true);
		} else {
			setPlanning(false);
			setEmail('');
		}
	};

	const sendPlans = () => {};

	return (
		<DrawerMUI
			open={open}
			onClose={onCancel}
			anchor="right"
			PaperProps={{
				style: {
					width: isMd ? '100%' : '760px',
					padding: ' 0 24px'
				}
			}}
		>
			<Box
				sx={{
					display: 'flex',
					borderBottom: '1px solid #eee',
					marginBottom: '1rem',
					alignItems: 'center'
				}}
			>
				<IconButton
					edge="start"
					color="inherit"
					onClick={onCancel}
					aria-label="close drawer"
					sx={{ width: '50px', height: '50px' }}
				>
					<CloseIcon />
				</IconButton>
				<DialogTitle
					sx={{ paddingLeft: '0', textTransform: 'none', fontSize: '.85rem' }}
					variant="overline"
				>
					AI пресс-секретарь ПушкинЪ
				</DialogTitle>
			</Box>
			<ChatGPTDrawerStyle>
				<div className="editorWrapper">
					<Box sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								flexWrap: 'wrap',
								marginBottom: '.5rem',
								gap: '.5rem'
							}}
						>
							<ClearButtonStyle
								startIcon={<CachedOutlinedIcon />}
								style={{ ...textStyle }}
								disabled={
									Iveno.Checker.isEmpty(str) &&
									Iveno.Checker.isEmpty(inputs) &&
									Iveno.Checker.isEmpty(result) &&
									Iveno.Checker.isEmpty(files)
								}
								onClick={deleteThread}
								variant="outlined"
								color="foreground"
							>
								Обновить диалог
							</ClearButtonStyle>

							{loadResponse ? (
								<ClearButtonStyle
									disabled={!loading}
									onClick={cancelRequest}
									variant="outlined"
									color="foreground"
									startIcon={<CloseIcon />}
								>
									Отмена
								</ClearButtonStyle>
							) : (
								<ClearButtonStyle
									startIcon={<DeleteForeverOutlinedIcon />}
									style={textStyle}
									disabled={
										Iveno.Checker.isEmpty(str) &&
										Iveno.Checker.isEmpty(inputs) &&
										Iveno.Checker.isEmpty(result) &&
										Iveno.Checker.isEmpty(files) &&
										Iveno.Checker.isEmpty(email) &&
										Iveno.Checker.isEmpty(siteText)
									}
									onClick={clearInputs}
									variant="outlined"
									color="foreground"
								>
									Очистить
								</ClearButtonStyle>
							)}
						</Box>

						{loadResponse ? (
							<ChatGPTPreloader response={result} callback={() => setLoadResponse(false)} />
						) : (
							<>
								<Box sx={{ position: 'relative', width: '100%' }}>
									<TextFieldStyle
										id="outlined-multiline-static"
										label="Задание"
										InputLabelProps={{
											shrink: true
										}}
										multiline
										InputProps={{
											startAdornment: (
												<Box
													sx={{
														marginRight: 'auto',
														background: 'white'
													}}
												>
													{chips.map((chip, index) => {
														if (chip.select) {
															return (
																<Chip
																	key={index}
																	label={chip.label}
																	onClick={() =>
																		handleClickChip(chip.label, true, chip.active)
																	}
																	style={{
																		cursor: 'pointer',
																		backgroundColor: chip.active
																			? '#f0f0f0'
																			: 'transparent'
																	}}
																/>
															);
														}

														return (
															<Chip
																key={index}
																label={chip.label}
																onClick={() => handleClickChip(chip.label)}
																style={{
																	cursor: 'pointer',
																	backgroundColor: chip.active
																		? '#f0f0f0'
																		: 'transparent'
																}}
															/>
														);
													})}
												</Box>
											)
										}}
										value={str}
										onChange={(e) => setStr(e.target.value)}
										minRows={3}
										maxRows={5}
										sx={{
											'.MuiInputBase-root': {
												flexDirection: 'column',
												gap: '.5rem'
											}
										}}
										style={textStyle}
										placeholder={
											planning
												? 'Подсказка для планирования'
												: 'Укажите задание для ассистента. Например: "Напиши статью", "Подготовь пресс-релиз", "Выдели главные мысли из текста" и т.п.'
										}
									/>
								</Box>

								<TextFieldStyle
									id="outlined-multiline-static"
									label={planning ? 'Уточнения о компании' : 'Уточнения'}
									placeholder={
										planning
											? 'Укажите детали о компании'
											: 'Укажите информацию, на основе которой нужно выполнить задание'
									}
									multiline
									value={inputs}
									style={textStyle}
									onChange={(e) => setInputs(e.target.value)}
									minRows={3}
									maxRows={5}
									disabled={loading || !str}
								/>

								{planning ? (
									<>
										<EmailInputAI email={email} setEmail={setEmail} />
										<TextInputAI value={siteText} setValue={setSiteText} />
									</>
								) : (
									<UploadFiles files={files} setFiles={setFiles} />
								)}

								<div className="buttons">
									<ButtonMUI
										color="systemRed"
										startIcon={<SendOutlinedIcon />}
										style={textStyle}
										sx={{
											color: 'white',
											borderRadius: '100px',
											alignItems: 'center',
											lineHeight: '0px',
											padding: '.6rem 1rem'
										}}
										variant="contained"
										disabled={
											Iveno.Checker.isEmpty(str) &&
											// && !(files[0]?.fileClass === 'audio_format')
											!files[0]
										}
										onClick={() => sendMessage()}
									>
										Отправить
									</ButtonMUI>
								</div>
							</>
						)}
					</Box>
				</div>

				{!_.isEmpty(result) && !planning && (
					<>
						{!loadResponse && (
							<div className="resultWrapper">
								{isImage ? (
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center'
										}}
									>
										{isImgButtons && (
											<>
												<img
													style={{ width: '100%', margin: '1rem 0' }}
													src={result}
													alt=""
												/>
												<Box sx={{ display: 'flex', gap: '1rem' }}>
													{Array(4)
														.fill('null')
														.map((_, index) => (
															<ButtonMUI
																key={index}
																variant="contained"
																onClick={() => makeWebhookRequest(false, index + 1)}
															>
																{index + 1}
															</ButtonMUI>
														))}
												</Box>
											</>
										)}
										{!isImgButtons && (
											<AddToEditImgButton
												controller={controller}
												result={result}
												setOpen={setOpen}
											/>
										)}
									</Box>
								) : (
									<>
										<Typography dangerouslySetInnerHTML={{ __html: result }} />
										<AddToEditButton
											controller={controller}
											result={result}
											setOpen={setOpen}
										/>
										<CopyButton result={result} />
									</>
								)}
							</div>
						)}
					</>
				)}
				{!_.isEmpty(resultPlan) && planning && (
					<Box
						sx={{
							mt: '1rem',
							mb: '1rem',
							display: 'flex',
							flexDirection: 'column',
							gap: '1rem'
						}}
					>
						<ArticlesList>
							{_.map(resultPlan, (item) => {
								return (
									<Link
										style={{ textDecoration: 'none' }}
										to={'/' + item.id}
										key={item.id}
									>
										<ArticleItem
											article={item}
											loading={loading}
											setItems={setResultPlan}
											planItem
										/>
									</Link>
								);
							})}
						</ArticlesList>
						<ButtonMUI
							color="primary"
							startIcon={<SendOutlinedIcon />}
							style={textStyle}
							sx={{
								color: 'white',
								borderRadius: '100px',
								alignItems: 'center',
								lineHeight: '0px',
								padding: '.6rem 1rem',
								margin: 'auto'
							}}
							variant="contained"
							disabled={
								Iveno.Checker.isEmpty(str) &&
								// && !(files[0]?.fileClass === 'audio_format')
								!files[0]
							}
							onClick={() => sendMessage()}
						>
							Применить
						</ButtonMUI>
					</Box>
				)}
			</ChatGPTDrawerStyle>
		</DrawerMUI>
	);
};

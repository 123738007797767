import 'antd/dist/reset.css';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';
import Theme from '../Config/Theme';
import { useEffect, useState } from 'react';
import { SystemEvents } from '../Config/Config';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { AppRoutes } from '../Config/AppRoutes';
import Iveno from '../Libs/Iveno/Iveno';
import { useUpdate } from 'ahooks';
import numeral from 'numeral';
import 'numeral/locales/ru';
import Session from '../Config/Session';

import { useNotification } from '../hooks/useNotification';

const HostApp = function () {
	const [loading, setLoading] = useState(true);
	const update = useUpdate();

	const { SnackbarProviderComponent } = useNotification();

	useEffect(() => {
		const scopeID = Iveno.Crypt.random();
		Iveno.Bus.on(
			SystemEvents.onConfigRefreshed,
			() => {
				update();
			},
			scopeID
		);
		return () => {
			Iveno.Bus.offScope(scopeID);
		};
	}, []);

	useEffect(() => {
		numeral.locale('ru');
		dayjs.locale('ru');
		let localizedFormat = require('dayjs/plugin/localizedFormat');
		dayjs.extend(localizedFormat);
		let timezone = require('dayjs/plugin/timezone');
		dayjs.extend(timezone);
		let quarterOfYear = require('dayjs/plugin/quarterOfYear');
		dayjs.extend(quarterOfYear);
		let relativeTime = require('dayjs/plugin/relativeTime');
		dayjs.extend(relativeTime);
		Session.init()
			.then(() => {
				setLoading(false);
			})
			.catch((err) => {});
	}, []);

	return (
		<ConfigProvider theme={Theme} locale={ruRU}>
			<SnackbarProviderComponent>{!loading && <AppRoutes />}</SnackbarProviderComponent>
		</ConfigProvider>
	);
};

export default HostApp;

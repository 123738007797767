import {
	Alert,
	Box,
	FormControl,
	FormHelperText,
	IconButton,
	Input,
	InputAdornment,
	InputLabel
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState } from 'react';
import { ActionButton } from './ActionButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useValidate } from '../../hooks/useValidate';

export const PasswordInput = ({
	controller,
	action = 'password',
	label = 'Пароль',
	noComplete,
	rememberPass,
	changePass
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const { isValue, setValue, isError, isErrorAlert, isHelperText } = useValidate(
		controller,
		action
	);

	const handleShowPassword = () => setShowPassword((show) => !show);

	return (
		<FormControl error={isError} fullWidth variant="standard">
			<InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>
			<Input
				id="standard-adornment-password"
				type={showPassword ? 'text' : 'password'}
				value={isValue}
				onChange={(e) => setValue(e.target.value)}
				disabled={controller.loading}
				autoComplete={noComplete && 'new-password'}
				startAdornment={
					<InputAdornment position="start">
						<LockOutlinedIcon />
					</InputAdornment>
				}
				endAdornment={
					<InputAdornment position="end">
						<IconButton onClick={handleShowPassword} onMouseDown={(e) => e.preventDefault()}>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
			/>
			{isError && <FormHelperText>{isHelperText}</FormHelperText>}

			{rememberPass && (
				<Box>
					<ActionButton onClick={changePass} style={{ marginLeft: 'auto', display: 'block' }}>
						Я не помню пароль
					</ActionButton>
				</Box>
			)}

			{isErrorAlert && <Alert severity="error">{isErrorAlert}</Alert>}
		</FormControl>
	);
};

import { useState } from 'react';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import TransitEnterexitOutlinedIcon from '@mui/icons-material/TransitEnterexitOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useLocation } from 'react-router';

export const AddToEditButton = ({ controller, result, setOpen }) => {
	const [isAdded, setIsAdded] = useState(false);
	const theme = useTheme();
	const redColor = theme.palette.systemRed.main;

	const { pathname } = useLocation();

	const addToEditor = () => {
		setIsAdded(true);
		setTimeout(() => {
			setIsAdded(false);
			setOpen(false);
		}, 500);
		if (pathname === '/social') {
			return controller.setSocialData('textPost', result);
		}
		controller.setData('bodyText', result);
	};

	const renderIcon = (
		<>
			{isAdded ? (
				<DoneOutlinedIcon sx={{ color: redColor }} />
			) : (
				<>
					<TransitEnterexitOutlinedIcon sx={{ color: redColor }} onClick={addToEditor} />
				</>
			)}
		</>
	);

	return (
		<>
			{controller.getIsEdit() && (
				<Tooltip title={isAdded ? 'Отправлено' : 'В редактор'}>
					<IconButton>{renderIcon}</IconButton>
				</Tooltip>
			)}
		</>
	);
};

import Controller from '../Libs/Controller';

export const userDataController = (() => {
	const controller = new Controller('getAuthorData');

	const register = (update) => {
		if (update) {
			controller.addObserver(update);
		}
		return controller;
	};

	return register;
})();
